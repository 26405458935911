import React from "react";
import ConfirmDialog from "../../../../components/Base/ConfirmDialog";
import useStyles from "../../style";

function WithdrawTokenAccount(props: any) {
  const classes = useStyles();
  const {
    isOpenEditPopup,
    setIsOpenEditPopup,
    handleCreateUpdateData,
    disableButton,
    setTokenAddress,
  } = props;

  const handleSubmitPopup = () => {
    handleCreateUpdateData();
  };

  return (
    <>
      <ConfirmDialog
        title={"Withdraw Token"}
        open={isOpenEditPopup}
        confirmLoading={disableButton}
        onConfirm={handleSubmitPopup}
        onCancel={() => {
          setIsOpenEditPopup(false);
        }}
      >
        <div className={classes.formControl}>
          <div>
            <input
              type="text"
              style={{
                marginBottom: "10px",
              }}
              className={classes.formControlInput}
              placeholder="Enter Token Account"
              onChange={(e) => {
                setTokenAddress(e.target.value);
              }}
            />
          </div>
          <div></div>
        </div>
      </ConfirmDialog>
    </>
  );
}

export default WithdrawTokenAccount;
