import moment from "moment";
import BigNumber from "bignumber.js";
import _ from "lodash";
import { BaseRequest } from "./Request";
import { apiRoute } from "../utils";
import FileDownload from "js-file-download";
import { EXPORT_USER_TYPE } from "../constants";
const queryString = require("query-string");

export const getUserList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/users`);
  url += "?" + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const exportUserList = async () => {
  // let queryParams: any = {};
  // if (tier >= 0 && with_bonus === 0) {
  //   queryParams = `tier=${tier}&with_bonus=${1}`;
  // } else if (tier >= 0 && with_bonus === 1) {
  //   queryParams = `tier=${tier}&with_bonus=${0}`;
  // } else if (tier === -1 && with_bonus === 0) {
  //   queryParams = `with_bonus=${1}`;
  // } else if (tier === -1 && with_bonus === 1) {
  //   queryParams = `with_bonus=${0}`;
  // } else if (tier < 0 && with_bonus === 0) {
  //   queryParams = `with_bonus=${1}`;
  // } else if (tier < 0 && with_bonus === 1) {
  //   queryParams = `with_bonus=${0}`;
  // }
  // if (with_bonus === "0") {
  //   queryParams = `with_bonus=${true}`;
  // } else if (tier >= 0 && with_bonus === "1") {
  //   queryParams = `tier=${tier}&with_bonus=${true}`;
  // } else if (tier === -1 && with_bonus === "1") {
  //   queryParams = `with_bonus=${true}`;
  // } else if (tier === -1 && with_bonus === "2") {
  //   queryParams = `with_bonus=${false}`;
  // } else if (tier < 0 && with_bonus === "1") {
  //   queryParams = `with_bonus=${true}`;
  // } else if (tier < 0 && with_bonus === "2") {
  //   queryParams = `with_bonus=${false}`;
  // } else if (tier >= 0 && with_bonus === "2") {
  //   queryParams = `tier=${tier}&with_bonus=${false}`;
  // } else if (with_bonus === "3") {
  //   queryParams = `with_bonus=${false}`;
  // }

  const baseRequest = new BaseRequest();
  let url = apiRoute(
    `/users/download?type=${EXPORT_USER_TYPE.USER_LIST}`
  );
  const fileData = (await baseRequest.postDownload(url, {})) as any;
  FileDownload(
    fileData,
    `launchpad_users_${moment().format("DD_MM_YYYY")}.csv`
  );
};

export const reloadCachedUserList = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/users/reload`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const setBonusPointUser = async (param: {
  walletAddress: string;
  bonusPoint: number;
}) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/users/bonus`;

  const response = (await baseRequest.put(url, {
    wallet_address: param.walletAddress,
    bonus_point: param.bonusPoint,
  })) as any;
  const resObject = await response.json();

  return resObject;
};

export const setBonusPointUserCampaign = async (param: {
  walletAddress: string;
  bonusPoint: number;
  poolId: number;
}) => {
  const baseRequest = new BaseRequest();
  let url = `/admin/user-bonus`;

  const response = (await baseRequest.patch(url, {
    wallet_address: param.walletAddress,
    bonus_point: param.bonusPoint,
    campaignId: param.poolId
  })) as any;
  const resObject = await response.json();

  return resObject;
};

export const wipeAllBonuses = async () => {
  const baseRequest = new BaseRequest();
  let url = `/admin/users/bonuses`;

  const response = (await baseRequest.delete(url, {})) as any;
  const resObject = await response.json();

  return resObject;
};

export const downloadUserList = async (fileId: number, fileName: string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/users/download/${fileId}`);
  const fileData = (await baseRequest.postDownload(url, {})) as any;
  FileDownload(fileData, fileName);
};

export const getFileExportList = async (params: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/users/export-files`);
  url += "?" + queryString.stringify(params);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const importBonus = async (file: any) => {
  const baseRequest = new BaseRequest();
  var form_data = new FormData();
  form_data.append("bonus", file);
  let url = apiRoute(`/users/bonus/import`);

  const response = (await baseRequest.postImage(url, form_data)) as any;
  const resObject = await response.json();
  return resObject;
};
