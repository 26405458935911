import React, { useEffect, useState } from "react";
import useStyles from "../style";
import { renderErrorCreatePool } from "../../../utils/validate";

function RefundTerms(props: any) {
  const classes = useStyles();
  const { register, errors, poolDetail, isEditDisabled } = props;
  const renderError = renderErrorCreatePool;
  // const isDeployed = !!poolDetail?.is_deploy;

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Refund Terms</label>
        <input
          type="text"
          name="refund_terms"
          defaultValue={poolDetail?.refund_terms}
          ref={register({})}
          maxLength={255}
          disabled={isEditDisabled}
          className={classes.formControlInput}
          style = {{
            backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
          }}
          // disabled={isDeployed}
        />
      </div>
    </>
  );
}

export default RefundTerms;
