import { useEffect } from "react";
import { useHistory } from "react-router";
import { adminRoute } from ".";
import { PERMISSIONS } from "../constants";

export const UseCheckPermissions = (permission: any) => {
  const history = useHistory();
  const userString = localStorage?.getItem("permissions");
  let role = localStorage.getItem("role");
  if (role) {
    // Remove leading and trailing quotes if they exist
    role = role.replace(/^"|"$/g, "");
  }

  useEffect(() => {
    if (!userString) {
      // Redirect the user to the login page
      localStorage.clear();
      history.push(adminRoute("/Login"));
    }
  }, [userString, history]);
   
  if (!PERMISSIONS) {
     return true;
   }
  if (role === "SUPERADMIN") {
    return true;
  }

  if (!userString) {
    return false;
  }

  let permissions;
  try {
    permissions = JSON.parse(userString);
  } catch (error) {
    console.error("Error parsing permissions JSON:", error);
    // Handle the error gracefully, e.g., redirect to login page
    return false;
  }
  const modulePermission = permissions?.find(
    (permissionModule: any) => permissionModule.name === permission
  );
  if (!modulePermission) {
    return false;
  } else {
    return true;
  }
};
