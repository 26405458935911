import React, { useEffect, useState } from "react";
import { Button, TextField, FormControl } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Col, Row } from "reactstrap";
import {
  addExchangeList,
  getExchangeList,
  getExchangeListById,
  updateExchangeById,
} from "../../request/exchange-list";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import ImageUploader from "../PoolCreate/Components/ImageUploader";
import { useHistory, useParams, withRouter } from "react-router-dom";

const ExchangeListForm = () => {
  const [image, setImage] = useState("");
  // @ts-ignore
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      exchangeName: "",
      url: "",
    },
  });

  const [exchangeList, setExchangeList] = useState([]);
  const fetchExchangesData = async () => {
    try {
      const response = await getExchangeList();
      if (response?.status === 200) {
        setExchangeList(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchExchangesData();
  }, [])

  const handleFileSelect = (file: any) => {
    setImage(file);
  };

  const fetchData = async (id: number) => {
    try {
      const response = await getExchangeListById(id);
      if (response?.status === 200) {
        setValue("exchangeName", response.data.name);
        setValue("url", response.data.base_url);
        setImage(response.data.logo);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data: any) => {
    if(!data?.exchangeName) {
      dispatch(alertFailure("Please enter exchange name"));
      return;
    }
    if(!data?.url) {
      dispatch(alertFailure("Please enter exchange url"));
      return;
    }
    if(!image) {
      dispatch(alertFailure("Please enter exchange logo"));
      return;
    }
    if(!id) {
      const duplicateExchangeName : any = exchangeList.find((exchange : any) => exchange.name?.toLowerCase() === data?.exchangeName?.toLowerCase());
      if(duplicateExchangeName && duplicateExchangeName.name?.toLowerCase() === data?.exchangeName?.toLowerCase()) {
        dispatch(alertFailure(`${data?.exchangeName?.toUpperCase()} is already added`));
        return;
      }
    }
    let response;
    try {
      if (id) {
        response = await updateExchangeById(
          { base_url: data.url, name: data.exchangeName, logo: image },
          Number(id)
        );
      } else {
        response = await addExchangeList({
          base_url: data.url,
          name: data.exchangeName,
          logo: image,
        });
      }

      if (response?.status === 200) {
        dispatch(alertSuccess("Added successfully"));
        history.push("/dashboard/exchange-list");
      } else if (response?.status === 400) {
        dispatch(alertFailure(response.message));
      }
      fetchExchangesData();
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchData(Number(id));
    }
  }, [id]);

  return (
    <DefaultLayout>
      <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
        <FormControl
          variant="outlined"
          margin="normal"
          fullWidth
          style={{ margin: "0" }}
        >
          <label htmlFor="exchange" style={{ marginBottom: "5px" }}>
            Exchange
          </label>
          <TextField
            style={{ margin: "0px" }}
            type="text"
            id="exchange"
            variant="outlined"
            margin="normal"
            fullWidth
            name="exchangeName"
            inputRef={register({ required: "Exchange name is required" })} // Register input with validation
            error={!!errors.exchangeName}
          />
          {errors.exchangeName && (
            <div style={{ color: "red" }}>{errors.exchangeName.message}</div>
          )}
        </FormControl>

        <FormControl
          variant="outlined"
          margin="normal"
          fullWidth
          style={{ marginTop: "10px" }}
        >
          <label
            htmlFor="url"
            style={{ marginTop: "5px", marginBottom: "8px" }}
          >
            URL
          </label>
          <TextField
            style={{ margin: "0px" }}
            type="text"
            id="url"
            variant="outlined"
            margin="normal"
            fullWidth
            name="url"
            inputRef={register({ required: "URL is required" })} // Register input with validation
            error={!!errors.url}
          />
          {errors.url && (
            <div style={{ color: "red" }}>{errors.url.message}</div>
          )}
        </FormControl>

        <Row style={{ marginBottom: "20px" }}>
          <Col className="col-12">
            <label className="form-label" style={{ marginBottom: "5px" }}>
              Image
            </label>
            <ImageUploader
              width="50%"
              onFileSelect={handleFileSelect}
              initialSrc={image}
            />
          </Col>
        </Row>

        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "10px" }}
          onClick={() => {
            history.push("/dashboard/exchange-list");
          }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </DefaultLayout>
  );
};

export default withRouter(ExchangeListForm);