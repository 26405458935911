import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useStyles from "./style";
import {
  deleteExchangeById,
  getConnectedExchangeList,
  getExchangeList,
  rpcStatusUpdate,
} from "../../request/exchange-list";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { Link, useHistory, withRouter } from "react-router-dom";
import ConfirmDialog from "../../components/Base/ConfirmDialog";

const ExchangeListing = () => {
  const [exchangeList, setExchangeList] = useState([]);
  let history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();


  const fetchData = async () => {
    try {
      const response = await getExchangeList();
      if (response?.status === 200) {
        console.log("success", response);
        setExchangeList(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const deleteWallet = async (exchangeList: any) => {
  //   try {
  //     if (!window.confirm("Are you sure?")) {
  //       return;
  //     }
  //     const response = await deleteExchangeById(exchangeList?.id);

  //     if (response?.status === 200) {
  //       dispatch(alertSuccess("Deleted Successfully"));
  //       fetchData();
  //     } else {
  //       dispatch(alertFailure("Error While Deleting Exchange"));
  //       fetchData();
  //     }
  //   } catch (e) {
  //     dispatch(alertFailure("Error While Deleting Exchange"));
  //     fetchData();
  //   }
  // };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSwitchChange = async (id: number, currentStatus: number) => {
    try {
      const isActive = currentStatus === 1 ? 0 : 1;
      const response = await rpcStatusUpdate(isActive, id);
      if (response?.status === 200) {
        fetchData(); // Refresh the data after updating status
        dispatch(alertSuccess("Status updated successfully"));
      } else {
        dispatch(alertFailure("Failed to update status"));
      }
    } catch (error) {
      dispatch(alertFailure("An error occurred while updating status"));
      console.log(error);
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [connectedExchanges, setConnectedExchanges] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedExchange, setSelectedExchange] = useState(null);

  // This function will be called when the user confirms deletion in the modal
  const handleConfirmDelete = async () => {
    try {
      if (selectedExchange) {
        //@ts-ignore
        const response = await deleteExchangeById(selectedExchange.id);
        if (response?.status === 200) {
          dispatch(alertSuccess("Deleted Successfully"));
          fetchData();
        } else {
          dispatch(alertFailure("Error While Deleting Wallet"));
        }
      }
    } catch (e) {
      dispatch(alertFailure("Error While Deleting Wallet"));
    } finally {
      setIsDeleteModalOpen(false); // Close the modal after the action
    }
  };


  const deleteWallet = async (exchangeList: any) => {
    try {
      const connectedExchangeResponse = await getConnectedExchangeList(
        exchangeList?.id
      );

      // If the exchange is connected to pools, show the modal
      if (
        connectedExchangeResponse.status == 403 &&
        connectedExchangeResponse?.message?.CampaignDetails.length > 0
      ) {
        setConnectedExchanges(
          connectedExchangeResponse?.message?.CampaignDetails
        );
        setIsModalOpen(true);
      } else {
        // Store selected exchange for deletion confirmation
        setSelectedExchange(exchangeList);
        setIsDeleteModalOpen(true); // Open custom confirmation modal
      }
    } catch (e) {
      dispatch(alertFailure("Error While Deleting Wallet"));
    }
  };
  const tableHeaderData = [
    "#",
    "Name",
    "Icon",
    "Url",
    "Enable/Disable",
    "Actions",
  ];
  return (
    <DefaultLayout>
      {/* <Breadcrumb title="dashboard" breadcrumbItem="Exchange List" /> */}

      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: "30px" }}
        onClick={() => {
          history.push("/exchange-list/add");
        }}
      >
        Add Exchange
      </Button>
      <TableContainer component={Paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              {tableHeaderData.map((tHeadCell, index) => (
                <TableCell key={index} className={classes.tableHeader}>
                  {tHeadCell}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={classes.tableBody}>
            {exchangeList.map((exchange: any) => (
              <TableRow className={classes.tableRow} key={exchange.id}>
                <TableCell className={classes.tableCell} align="left">
                  <span className={`${classes.tableCellSpan}`}>
                    {exchange.id}
                  </span>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <span className={`${classes.tableCellSpan}`}>
                    {exchange?.name}
                  </span>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <span className={`${classes.tableCellSpan}`}>
                    <img
                      width={40}
                      height={40}
                      alt={exchange.name}
                      src={exchange.logo}
                    ></img>
                  </span>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <span className={`${classes.tableCellSpan}`}>
                    {exchange?.base_url}
                  </span>
                </TableCell>
                <TableCell className={classes.tableCell} align="left">
                  <span className={`${classes.tableCellSpan}`}>
                    <Switch
                      checked={exchange?.isActive === 1}
                      onChange={(e) =>
                        handleSwitchChange(exchange.id, exchange.isActive)
                      }
                    />
                  </span>
                </TableCell>
                <TableCell align="left">
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      history.push(`/exchange-list/edit/${exchange?.id}`);
                    }}
                    disabled={!exchange?.isActive}
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      deleteWallet(exchange);
                    }}
                    disabled={!exchange?.isActive}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <>
        <ConfirmDialog
          title={"Connected Pools"}
          open={isModalOpen}
          confirmLoading={false}
          onConfirm={() => {
            setIsModalOpen(false);
          }}
          onCancel={() => {
            setIsModalOpen(false);
          }}
          label="confirm"
        >
          <div
            style={{
              padding: "20px",
              backgroundColor: "#f9f9f9",
              borderRadius: "8px",
              maxWidth: "600px",
              margin: "0 auto",
            }}
          >
            {connectedExchanges.length > 0 && (
              <div>
                <p
                  style={{
                    fontSize: "16px",
                    color: "#333",
                  }}
                >
                  This exchange is connected to the following pools. Please
                  remove it from the pool before deleting:
                </p>
                <hr />
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {connectedExchanges?.map((exchange: any, index: number) => (
                    <li key={exchange.id} style={{ margin: "2px" }}>
                      <Link
                        to={`/dashboard/campaign-detail/${exchange.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <span>{index + 1}.</span>
                        <span>
                          {`  ${exchange?.title
                            .toLowerCase()
                            .split(" ")
                            .map(
                              (word: any) =>
                                word.charAt(0).toUpperCase() + word.slice(1)
                            )
                            .join(" ")}`}
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </ConfirmDialog>

        <ConfirmDialog
          title="Confirm Deletion"
          open={isDeleteModalOpen}
          confirmLoading={false}
          onConfirm={handleConfirmDelete} // When the user confirms deletion
          onCancel={() => setIsDeleteModalOpen(false)} // Cancel action
          label="Confirm"
        >
          <div style={{ padding: "20px" }}>
            <p>Are you sure you want to delete this Exchange?</p>
          </div>
        </ConfirmDialog>
      </>
    </DefaultLayout>
  );
};


export default withRouter(ExchangeListing);
