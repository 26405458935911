import React, { useEffect } from "react";
import useStyles from "../style";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Controller } from "react-hook-form";
import { Select, MenuItem } from "@material-ui/core";

function UserTier(props: any) {
  const classes = useStyles();
  const { errors, poolDetail, control, setValue, isEditDisabled } = props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail?.tier_based_visibility) {
      setValue("tier_based_visibility", poolDetail?.tier_based_visibility);
    }
  }, [poolDetail]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Tier Based Visibility</label>
        <Controller
          control={control}
          defaultValue="0"
          className={classes.formControlInput}
          name="tier_based_visibility"
          disabled = {isEditDisabled}
          as={
            <Select
              labelId="tier_based_visibility"
              id="tier_based_visibility"
              name="tier_based_visibility"
            >
              <MenuItem value="0">Everyone</MenuItem>
              <MenuItem value="1">Bronze & Up</MenuItem>
              <MenuItem value="2">Silver & Up</MenuItem>
              <MenuItem value="3">Gold & Up</MenuItem>
              <MenuItem value="4">Diamond Only</MenuItem>
            </Select>
          }
        />
        <p className={classes.formErrorMessage}>
          {renderError(errors, "tier_based_visibility")}
        </p>
      </div>
    </>
  );
}

export default UserTier;
