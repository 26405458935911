import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { deleteRPCUrl, getRPCUrls, rpcStatusUpdate } from "../../request/rpc";
import { RPCListResponse } from "./rpc.interface";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
} from "@material-ui/core";
import { Switch } from "antd";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { UseCheckPermissions } from "../../utils/useCheckPermissions";
import NoPermisisons from "../../components/Base/NoPermissions";
// @ts-ignore
import { NotificationManager } from "react-notifications";
import EmptyTable from "../../components/Base/Emptytable";

const RPCListing = () => {
  const [rpcUrls, setRpcUrls] = useState<RPCListResponse[]>([]);
  const history = useHistory();
  const fetchData = async () => {
    try {
      const response = await getRPCUrls();
      if (response?.status === 200) {
        console.log("success", response);
        setRpcUrls(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateStatus = async (rpcUrl: RPCListResponse) => {
    try {
      const isActive = rpcUrl?.isActive == 1 ? 0 : 1;
      const Id = rpcUrl?.id;
      const response = await rpcStatusUpdate(isActive, Id);
      fetchData();
      NotificationManager.success("Status updated");
    } catch (e) {}
  };

  const deleteRpc = async (rpcUrl: RPCListResponse) => {
    try {
      const response = await deleteRPCUrl(rpcUrl?.id);
      fetchData();
      NotificationManager.success("deleted successfully");
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  const isEditRPCURL = UseCheckPermissions("EDIT_RPC_URLS");
  const isViewRPCURL = UseCheckPermissions("VIEW_RPC_URLS");

  return (
    <DefaultLayout>
      {isViewRPCURL ? (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{ marginBottom: "30px" }}
            onClick={() => {
              history.push("add-rpc-url");
            }}
            disabled={!isEditRPCURL}
          >
            Add RPC URL
          </Button>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  {/*<TableCell>Id</TableCell>*/}
                  <TableCell>Network</TableCell>
                  <TableCell>URL</TableCell>
                  <TableCell>Active</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                { rpcUrls.length > 0 ? rpcUrls?.map((rpcUrl) => (
                  <TableRow key={rpcUrl.id}>
                    {/*<TableCell>{rpcUrl.id}</TableCell>*/}
                    <TableCell>{rpcUrl.network}</TableCell>
                    <TableCell>{rpcUrl.url}</TableCell>
                    <TableCell>
                      <Switch
                        onClick={() => {
                          updateStatus(rpcUrl);
                        }}
                        checked={rpcUrl.isActive == 1}
                        disabled={!isEditRPCURL}
                      />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: "10px" }}
                        onClick={() => {
                          history.push(`edit-rpc-url/${rpcUrl?.id}`);
                        }}
                        disabled={!isEditRPCURL}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          deleteRpc(rpcUrl);
                        }}
                        disabled={!isEditRPCURL}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                )) : <EmptyTable title="rpc-url" />}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <NoPermisisons />
      )}
    </DefaultLayout>
  );
};

export default RPCListing;
