import React, {useEffect} from 'react';
import useStyles from "../style";
import {renderErrorCreatePool} from "../../../utils/validate";
import { Controller } from "react-hook-form";
import { Select, MenuItem } from "@material-ui/core";

function PoolRelationship(props: any) {
  const classes = useStyles();
  const { register, errors, poolDetail, control, setValue, isEditDisabled } =
    props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail.relationship_type) {
      setValue("relationship_type", poolDetail.relationship_type);
    }
  }, [poolDetail]);

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Relationship Type</label>
        <Controller
          control={control}
          defaultValue="None"
          className={classes.formControlInput}
          name="relationship_type"
          as={
            <Select
              labelId="relationship_type"
              id="relationship_type"
              name="relationship_type"
              disabled={isEditDisabled}
              style = {{
                backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
              }}
              // disabled={isDeployed}
            >
    <MenuItem value="None">None</MenuItem>
              {/* <MenuItem value="Incubation">Incubation</MenuItem> */}
              <MenuItem value="Standard IDO">Standard IDO</MenuItem>
              <MenuItem value="Flash Sale">Flash Sale </MenuItem>
              <MenuItem value="Giveaway">Giveaway</MenuItem>
              {/* <MenuItem value="Exclusive IDO">Exclusive IDO</MenuItem> */}
              <MenuItem value="Private Sale">Private Sale</MenuItem>
              <MenuItem value="Acceleration">Acceleration</MenuItem>
              {/* <MenuItem value="Standard IDO">Standard IDO</MenuItem> */}
              <MenuItem value="Diamond IDO">Diamond IDO</MenuItem>
              <MenuItem value="Flash Sale (Incubation)">Flash Sale (Incubation)</MenuItem>
              <MenuItem value="IDO (Labs Incubation)">IDO (Labs Incubation)</MenuItem>
              <MenuItem value="Giveaway (Labs Incubation)">Giveaway (Labs Incubation)</MenuItem>
              <MenuItem value="Seed (Labs Incubation)">Seed (Labs Incubation)</MenuItem>
              <MenuItem value="Private (Labs Incubation)">Private (Labs Incubation)</MenuItem>
            </Select>
          }
        />
        <p className={classes.formErrorMessage}>
          {renderError(errors, "relationship_type")}
        </p>
      </div>
    </>
  );
}

export default PoolRelationship;
