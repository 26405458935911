import React from 'react';
import useStyles from "../style";
import { renderErrorCreatePool } from "../../../utils/validate";
import { UseCheckPermissions } from '../../../utils/useCheckPermissions';

function ShortDescription(props: any) {
  const classes = useStyles();
  const {
    register, errors,
    poolDetail, isEditDisabled
  } = props;
  const renderError = renderErrorCreatePool;
  const maxLength = 100;



  return (
    <>
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Short description</label>
          <input
            type="text"
            name="short_description"
            defaultValue={poolDetail?.short_description}
            ref={register({ required: false, maxLength })}
            className={classes.formControlInput}
            disabled={isEditDisabled}
          />

          <p className={classes.formErrorMessage}>
            {renderError(errors, "short_description", maxLength)}
          </p>
        </div>
    </>
  );
}

export default ShortDescription;
