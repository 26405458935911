import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { useCommonStyle } from "../../../../../styles";
import { Group, PermissionsData } from "../../../utils/PermissionTypes";
import { GroupName, PermissionKey } from "../../../utils/PermissionEnums";

interface PermissionProps {
  checkedPermissions: {
    [key: string]: boolean;
  };
  handleSuperAdminToggle: (checked: boolean) => void;
  loginUser: any;
  allPermissions: PermissionsData;
  adminId: string;
  handleGroupChange: (groupName: Group, checked: boolean) => void;
  handlePermissionChange: (
    permissionKey: PermissionKey,
    checked: boolean
  ) => void;
  isSuperAdmin: boolean;
  checkedGroup: {
    [key: string]: boolean;
  };
  disabledPermissions: { [key: string]: boolean };
  disabledGroups: { [key: string]: boolean };
  permissionErrors: any;
  groupErrors: any;
}

export default function Permissions({
  checkedPermissions,
  handleSuperAdminToggle,
  loginUser,
  allPermissions,
  adminId,
  handleGroupChange,
  handlePermissionChange,
  isSuperAdmin,
  checkedGroup,
  disabledPermissions,
  disabledGroups,
  permissionErrors,
  groupErrors
}: PermissionProps) {
  const commonStyle = useCommonStyle();
  return (
    <div
      style={{
        marginBottom: "20px",
      }}
    >
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div>
            <h6>
              Permissions<span className={commonStyle.required}>*</span>
            </h6>
          </div>
        </AccordionSummary>
        <FormControlLabel
          control={
            <Switch
              checked={isSuperAdmin}
              onChange={(e) => handleSuperAdminToggle(e.target.checked)}
              name="superAdminToggle"
              disabled={loginUser.id === adminId || loginUser.role !== "SUPERADMIN"}
            />
          }
          label="Super Admin"
          style={{ marginLeft: "8px" }}
        />
        <AccordionDetails>
          <Grid container spacing={2}>
            {allPermissions.groups.map((group, index) => {
              return (
                <Grid item xs={4} key={index}>
                  <Accordion>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleGroupChange(group, e.target.checked)
                            }
                            checked={
                              checkedGroup[group.name as GroupName] ||
                              group.permissions.every(
                                (permission) =>
                                  !!checkedPermissions[permission.name]
                              )
                            }
                            disabled={
                              isSuperAdmin || disabledGroups[group.name]
                            }
                          />
                        }
                        label={
                          groupErrors[group.name] ? (
                            <Tooltip
                            arrow
                              title={
                                <p style={{ fontSize: 15 }}>
                                  {groupErrors[group.name]}
                                </p>
                              }
                            >
                              <b>{group.name}</b>
                            </Tooltip>
                          ) : (
                            <b>{group.name}</b>
                          )
                        }
                      />
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {group.permissions.map((permission) => {
                          return (
                            <div key={permission.id}>
                              {permission.label !== group.name && (
                                <FormControlLabel
                                  key={permission.id}
                                  control={<label />}
                                  label={<p>{permission.label}</p>}
                                  style={{ marginLeft: 25 }}
                                />
                              )}
                            </div>
                          );
                        })}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              );
            })}

            {allPermissions.singlePermissions.map((permission, index) => {
              return (
                permission.label && (
                  <Grid item xs={4} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!!checkedPermissions[permission.name]}
                          onChange={(e) =>
                            handlePermissionChange(
                              permission.name,
                              e.target.checked
                            )
                          }
                          disabled={
                            isSuperAdmin || disabledPermissions[permission.name]
                          }
                        />
                      }
                      label={
                        permissionErrors[permission.name] ? (
                          <Tooltip
                          arrow
                            title={
                              <p style={{ fontSize: 15 }}>
                                {permissionErrors[permission.name]}
                              </p>
                            }
                          >
                            <b>{permission.label}</b>
                          </Tooltip>
                        ) : (
                          <b>{permission.label}</b>
                        )
                      }
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
