import React from "react";
import ConfirmDialog from "../../../../components/Base/ConfirmDialog";
import useStyles from "../../style";

const UserDeleteAllParticipants = (props: any) => {
  const classes = useStyles();
  const {
    openEditPopup,
    setOpenEditPopup,
    handleDeleteAllParticipant,
    deleteAllUserParticipants,
    setDeleteAllUserParticipants,
  } = props;
  return (
    <>
      <ConfirmDialog
        title={"Enter Password"}
        open={openEditPopup}
        onConfirm={handleDeleteAllParticipant}
        onCancel={() => {
          setOpenEditPopup(false);
          setDeleteAllUserParticipants("")
        }}
        confirmLoading={false}
      >
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Password</label>
          <div>
            <input
              placeholder="Please enter password"
              className={`${classes.formInputBox}`}
              style={{ width: "100%" }}
              type="password"
              value={deleteAllUserParticipants}
              onChange={(e: any) =>
                setDeleteAllUserParticipants(e.target.value)
              }
              autoComplete='off'
              // disabled={isDeployed}
            />
          </div>
        </div>
      </ConfirmDialog>
    </>
  );
};

export default UserDeleteAllParticipants;
