import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { renderErrorCreatePool } from "../../../../utils/validate";
import useStyles from "../../style";
import { alertFailure, alertSuccess } from "../../../../store/actions/alert";
import { useDispatch } from "react-redux";
import TokenDepositForm from "../DepositToken/TokenDepositForm";
import { PublicKey } from "@solana/web3.js";
import BigNumber from "bignumber.js";
import {
  apiRoute,
  convertToTransaction,
  deriveTokenAccount,
  getTokenInfo,
  SendSolanaTransaction,
} from "../../../../utils";
import { BaseRequest } from "../../../../request/Request";

function SolanaDepositToken(props: any) {
  const classes = useStyles();
  const { poolDetail, permission } = props;
  const renderError = renderErrorCreatePool;
  const [isOpenEditPopup, setIsOpenEditPopup] = useState(false);
  const [amount, setAmount] = useState<any>("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const [disableButton, setDisableButton] = useState<Boolean>(false);

  const openPopupCreate = (e: any) => {
    setIsOpenEditPopup(true);
  };

  useEffect(() => {
    if (poolDetail.total_sold_coin) {
      setAmount(poolDetail.total_sold_coin);
    }
  }, [poolDetail, poolDetail.token, poolDetail.network_available]);

  const handleCreateUpdateData = async () => {
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure(`Please Connect your Solana Wallet`));
      return;
    }
    if (!amount || amount === "") {
      dispatch(alertFailure(`Please Enter Amount`));
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    else if (!confirm(`Do you want to deposit money?`)) {
      return false;
    }
    setLoading(true);

    try {
      setDisableButton(true);
      const tokenAddress = deriveTokenAccount(window?.solana?.publicKey.toString(), poolDetail?.token).toBase58();
      try {
        const baseRequest = new BaseRequest();
        let url = apiRoute(`/solana/transfer-tokens`);
        const body = {
          source_token_account: tokenAddress,
          dest_token_account: poolDetail.claimable_token_account,
          amount: amount,
          decimals: poolDetail.decimals,
          fee_payer: window?.solana?.publicKey.toString(),
        };
        const response = (await baseRequest.post(url, body)) as any;
        const data = await response.json();
        const tx = await convertToTransaction(data);
        const sig = await SendSolanaTransaction(tx);
        dispatch(alertSuccess(`Payment Added Successfully !!!`));
        setIsOpenEditPopup(false);
        setDisableButton(false);
        setLoading(false);
      } catch (err: any) {
        dispatch(alertFailure(err?.message));
        setDisableButton(false);
        setIsOpenEditPopup(false);
        setLoading(false);
      }
    } catch (e) {
      setDisableButton(false);
      dispatch(alertFailure("Deposit Fail !!!"));
      setIsOpenEditPopup(false);
      setLoading(false);
      return false;
    }
  };

  const [tokenBalance, setTokenBalance] = useState("");

  const getBalance = async () => {
    const account = await getTokenInfo(
      new PublicKey(poolDetail?.claimable_token_account)
    );
    const rawContractBalance = new BigNumber(
      account.amount.toString()
    ).toFixed();
    return new BigNumber(rawContractBalance)
      .div(new BigNumber(10).pow(poolDetail.decimals))
      .toFixed();
  };

  useEffect(() => {
    if (poolDetail.total_sold_coin) {
      setAmount(poolDetail.total_sold_coin);
    }
    const getTokenBalance = async () => {
      const balance = await getBalance();
      setTokenBalance(Number(balance).toString());
    };
    if (poolDetail.token && poolDetail.network_available) {
      getTokenBalance();
    }
  }, [poolDetail, poolDetail.token, poolDetail.network_available]);

  return (
    <>
      {isOpenEditPopup && (
        <TokenDepositForm
          isOpenEditPopup={isOpenEditPopup}
          setIsOpenEditPopup={setIsOpenEditPopup}
          renderError={renderError}
          setAmount={setAmount}
          amount={amount}
          handleCreateUpdateData={handleCreateUpdateData}
          disableButton={disableButton}
          loading={loading}
          acceptCurrency={poolDetail?.symbol}
        />
      )}
      <br />
      <label className={classes.formControlLabel}>
        Contract Balance: {tokenBalance} {poolDetail?.symbol}
      </label>
      <br />
      <div>
        <label className={classes.exchangeRateTitle}>Deposit Tokens</label>
      </div>

      <div className={`${classes.formControl} ${classes.flexRow}`}>
        <Button disabled = {!permission} variant="contained" color="primary" onClick={openPopupCreate}>
          Deposit Token
        </Button>
      </div>
    </>
  );
}

export default SolanaDepositToken;
