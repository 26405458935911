import React from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import {
  Button,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useHistory } from "react-router";
import { blogStatusUpdate, deleteBlogById, getBlog } from "../../request/blog";
import { useState, useEffect } from "react";
import useStyles from "../../../src/pages/UserList/style";
import Pagination from "@material-ui/lab/Pagination";
import { useDispatch } from "react-redux";
import { alertSuccess } from "../../store/actions/alert";
import EmptyTable from "../../components/Base/Emptytable";

const BlogListing = () => {
  const history = useHistory();
  const classes = useStyles();
  const [blogdetails, setBlogDetails] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const dispatch = useDispatch();
  const blogs = async () => {
    const queryParams: any = {
      page: currentPage,
    };
    try {
      const response = await getBlog(queryParams);
      setBlogDetails(response.data);
      setLastPage(response.data.lastPage);
    } catch (error) {
      console.error("Error fetching RSS feed:", error);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      blogs();
    }, 500);
    return () => clearTimeout(timer);
  }, [currentPage]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const updateStatus = async (blog: any) => {
    try {
      const isActive = blog?.is_active ? 0 : 1;
      const Id = blog?.id;
      const response = await blogStatusUpdate(isActive, Id);

      blogs();
      if (response.status == 200) {
        dispatch(alertSuccess("status is updated successful!"));
      }
    } catch (e) {}
  };

  const deleteBlog = async (id: any) => {
    try {
      const response = await deleteBlogById(id);
      if (response.status == 200) {
        dispatch(alertSuccess("Blog is deleted successful!"));
      }
      blogs();
    } catch (e) {}
  };
  return (
    <DefaultLayout>
      <Button
        variant="contained"
        color="primary"
        style={{ marginBottom: "30px" }}
        onClick={() => {
          history.push("add-blog");
        }}
      >
        Add Blog
      </Button>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/*<TableCell>Id</TableCell>*/}
              <TableCell>Blog</TableCell>
              <TableCell>Pool</TableCell>
              <TableCell>Active</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {blogdetails?.data?.length > 0 ? (
              blogdetails?.data?.map((blog: any) => (
                <TableRow key={blog.id}>
                  <TableCell>{blog.blog_title}</TableCell>
                  <TableCell>{blog.title}</TableCell>
                  <TableCell>
                    <Switch
                      onClick={() => {
                        updateStatus(blog);
                      }}
                      checked={blog.is_active}
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "10px" }}
                      onClick={() => {
                        history.push(`edit-blog/${blog?.id}`);
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        deleteBlog(blog?.id);
                      }}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <EmptyTable title="blog" />
            )}
          </TableBody>
        </Table>
        <>
          {blogdetails && lastPage > 1 && (
            <Pagination
              page={currentPage}
              className={classes.pagination}
              count={lastPage}
              onChange={handlePaginationChange}
            />
          )}
        </>
      </TableContainer>
    </DefaultLayout>
  );
};

export default BlogListing;
