import React, { useEffect, useState } from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Button } from "@material-ui/core";
import { useCommonStyle } from "../../../../styles";
import { getUserBonus } from "../../../../request/participants";
import { useDispatch } from "react-redux";
import { withRouter } from "react-router";
import { alertFailure, alertSuccess } from "../../../../store/actions/alert";
import Pagination from "@material-ui/lab/Pagination";
import useStylesTable from "./style_table";
import useStylesWinner from "./style_winner";
import { etherscanRoute } from "../../../../utils";
import { importUserBonus } from "../../../../request/pool";
import Link from "@material-ui/core/Link";
import AddBonusForm from "./AddBonusForm";

function UserBonus(props: any) {
  const commonStyle = useCommonStyle();
  const classesTable = useStylesTable();
  const classesWinner = useStylesWinner();
  const { poolDetail, isEditUserTab } = props;
  const dispatch = useDispatch();
  const [isOpenBonusModal, setIsOpenBonusModal] = useState(false);
  const [userBonus, setUserBonus] = useState([]);

  const [selectedFile, setSelectedFile] = useState(null);
  const [importResponse, setImportResponse] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(1);
  const [failure, setFailure] = useState(false);
  const [loading, setLoading] = useState(false);

  const getParticipantUserWithTier = async (
    poolId: any,
    searchParams?: any
  ) => {
    setLoading(true);
    try {
      let response = await getUserBonus(poolId, {
        ...searchParams,
      });
      if (response.status !== 200) {
        setFailure(true);
        setLoading(false);
        return;
      } else {
        setFailure(false);
        setLastPage(response?.data.lastPage || 1);
        setCurrentPage(response?.data.page || 1);
        setTotalRecords(response?.data.total || 1);
        setUserBonus(response?.data?.data || []);
        setLoading(false);
      }
    } catch (e) {
      console.log("ERROR: Fail Getting user bonus");
      console.log(e);
    }
  };

  useEffect(() => {
    getParticipantUserWithTier(poolDetail.id);
  }, [poolDetail]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSelectCSVFile = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  const [importParticipantsLoading, setImportBonusLoading] =
    useState(false);
  const handleImportCSV = async () => {
    if (selectedFile) {
      try {
        setImportBonusLoading(true);
        setImportResponse("");
        const res = await importUserBonus(poolDetail.id, selectedFile);
        dispatch(
          (res.status === 200 ? alertSuccess : alertFailure)(res.message)
        );
        if (res.status === 200) {
            setImportBonusLoading(false);
        }
        getParticipantUserWithTier(poolDetail.id);
      } catch (error) {
        setImportBonusLoading(false);
      }
    } else {
      dispatch(alertFailure("Please select file"));
    }
  };

  return (
    <>
      <div className={commonStyle.boxSearch} style={{ display: "flex" }}>
        <Grid item xs={6} style={{ display: "inline-block" }}>
          <input
            color="primary"
            type="file"
            accept=".csv, .xlsx"
            onChange={handleSelectCSVFile}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleImportCSV}
            disabled={!selectedFile || isEditUserTab}
          >
            {importParticipantsLoading ? (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classesWinner.loader}></div>{" "}
                <span style={{ marginLeft: "5px" }}>Uploading</span>
              </div>
            ) : (
              "Import Bonus"
            )}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "40px" }}
            onClick={() => setIsOpenBonusModal(true)}
            disabled={isEditUserTab}
          >
            Set Bonus
          </Button>
        </Grid>
      </div>

      <div
        className={commonStyle.boxSearch}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 20,
          marginBottom: 10,
        }}
      >
        {/* <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            className={commonStyle.inputSearch}
            onChange={searchDelay}
            placeholder="Search"
            autoComplete="off"
          />
          <img
            src="/images/icon-search.svg"
            alt=""
            style={{ marginLeft: -30 }}
          />
        </div> */}
      </div>

      <TableContainer
        component={Paper}
        className={`${commonStyle.tableScroll} ${classesTable.tableUserJoin}`}
      >
        <Table className={classesTable.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell size={"small"}></TableCell>
              <TableCell size={"small"}>Email</TableCell>
              <TableCell align="center" size={"medium"}>
                Wallet Address
              </TableCell>
              <TableCell align="center">Bonus</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {userBonus.map((row: any, index: number) => (
              <TableRow key={row.id}>
                <TableCell size={"small"}></TableCell>
                <TableCell component="th" scope="row" size={"small"}>
                  {row.email}
                </TableCell>
                <TableCell align="center" size={"medium"}>
                  <Link
                    href={etherscanRoute(row.wallet_address, poolDetail)}
                    target={"_blank"}
                  >
                    {row.wallet_address}
                  </Link>
                </TableCell>
                <TableCell align="center">{row.bonus_point}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        {failure && <p className={classesTable.errorMessage}>{failure}</p>}
        {!failure && (!userBonus || userBonus.length === 0) && !loading ? (
          <p className={classesTable.noDataMessage}>There is no data</p>
        ) : (
          <>
            {userBonus && lastPage > 1 && (
              <Pagination
                page={currentPage}
                className={classesTable.pagination}
                count={lastPage}
                onChange={handlePaginationChange}
              />
            )}
          </>
        )}
      </TableContainer>
      <AddBonusForm
        isOpen={isOpenBonusModal}
        setIsOpen={setIsOpenBonusModal}
        poolId={poolDetail?.id}
        getParticipantUserWithTier = {getParticipantUserWithTier}
      />
    </>
  );
}

export default withRouter(UserBonus);
