import React from 'react'

const NoPermisisons = () => {
  return (
    <div>
      <p
        style={{
          fontWeight: 500,
          marginTop: 30,
          textAlign: "center",
          fontSize: 15,
        }}
      >
        You don't have permission to see this page
      </p>
    </div>
  );
}

export default NoPermisisons