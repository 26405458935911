import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter, useLocation } from "react-router-dom";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { adminRoute } from "../../utils";
import PoolForm from "./PoolForm";
import BackButton from "../../components/Base/ButtonLink/BackButton";
import useStyles from "./style";
import { NETWORK_AVAILABLE } from "../../constants";
import { solanaConnectWallet, solanaDisconnectWallet, solanaSetWalletAddress } from "../../store/actions/solana-wallet";
import { useDispatch } from "react-redux";

const PoolCreate: React.FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const isEdit = false;
  const location: any = useLocation();
  const [poolDetailClone, setPoolDetailClone] = useState<any>({});
  const classes = useStyles();

  useEffect(() => {
    if (location.state?.poolDetail) {
      let poolData = { ...location.state?.poolDetail };
      poolData.id = null;
      console.log("Clone poolDetail", poolData);
      setPoolDetailClone(poolData);
    }
  }, [location]);

  const dispatch = useDispatch();

  return (
    <DefaultLayout>
      {poolDetailClone?.supported_token_types === NETWORK_AVAILABLE.SOLANA ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <BackButton to={adminRoute("/campaigns")} />
          {!window?.solana?.publicKey ? (
            window?.solana && window?.solana.isPhantom ? (
              <button
                className={classes.button}
                onClick={async () => {
                  const response = await window.solana.connect()
                  dispatch(solanaConnectWallet());
                    dispatch(solanaSetWalletAddress(response.publicKey.toString()));
                }}
              >
                Connect Solana Wallet
              </button>
            ) : (
              <p>No wallet found. Please download a supported Solana wallet</p>
            )
          ) : (
            <>
              <button className={classes.button}>
                {`Connected to ${window?.solana?.publicKey}`}
              </button>
              <button
                onClick={async () => {
                  await window.solana.disconnect()
                  dispatch(solanaDisconnectWallet())
                }}
                className={classes.button}
              >
                Disconnect
              </button>
            </>
          )}
        </div>
      ) : (
        <BackButton to={adminRoute("/campaigns")} />
      )}
      <PoolForm isEdit={isEdit} poolDetail={poolDetailClone} />
    </DefaultLayout>
  );
};

export default withRouter(PoolCreate);
