import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import Pagination from "@material-ui/lab/Pagination";
import { convertDateTimeToUnix } from "../../utils/convertDate";
import useStyles from "./style";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import NoPermisisons from "../../components/Base/NoPermissions";
import { getAdminLogs } from "../../request/admin-logs";
import AdminLogsRow from "./AdminRow";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import SearchForm from "./SearchForm";
import { PERMISSIONS } from "../../constants";
import { validateJson } from "../../utils";
import EmptyTable from "../../components/Base/Emptytable";

const countries = require("../../data/countries.json");
const countriesMapping: any = {};
countries.map((item: any) => {
  countriesMapping[item.code] = item.name;
  return item;
});

const tableHeaders = [
  "Admin Name",
  // "Wallet Address",
  "Action Type",
  "Module",
  "Pool Name",
  // "Pool Of Winner",
  "Update At",
  "Actions",
];

const AdminLogs: React.FC<any> = (props: any) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { data: loginUser } = useTypedSelector((state) => state.user);

  const [admins, setAdmins] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [filter, setFilter] = useState(false);
  const [currentOpen, setCurrentOpen] = useState("");
  const [startTime, setStartTime] = useState<Date | null>(null);
  const [finishTime, setFinishTime] = useState<Date | null>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(page);
  const [loading, setLoading] = useState(false);
  const [failure, setFailure] = useState(false);

  const getAdminListInfo = async (
    currentPage: any,
    searchQuery: any,
    startTime: any,
    finishTime: any,
    filter: any
  ) => {
    const queryParams = {
      page: currentPage,
      searchQuery: searchQuery,
    };

    try {
      setLoading(true);
      const resObject = await getAdminLogs(queryParams);
      if (resObject.status === 200) {
        const { total, page, lastPage, data } = resObject.data;
        setPage(page);
        setLastPage(lastPage);
        setAdmins(data);

        setFailure(false);
      } else {
        setFailure(true);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      setFailure(true);
    }
  };

  useEffect(() => {
    handleListQuery(currentPage, searchQuery, startTime, finishTime, filter);
  }, [dispatch, currentPage, searchQuery, startTime, finishTime, filter]);

  const handlePaginationChange = (event: any, page: number) => {
    setCurrentPage(page);
  };

  const handleSearch = (event: any) => {
    setCurrentPage(1);
    setSearchQuery(event.target.value);
  };

  const handleListQuery = (
    currentPage: number,
    searchQuery: string,
    startTime: Date | null,
    finishTime: Date | null,
    filter: boolean
  ) => {
    getAdminListInfo(
      currentPage,
      searchQuery,
      convertDateTimeToUnix(startTime),
      convertDateTimeToUnix(finishTime),
      filter
    );
  };

  const handleCurrentOpenSet = (id: string) => {
    setCurrentOpen(id);
  };

  let role : any = localStorage.getItem("role");

  return (
    <DefaultLayout>
      <>
        {(PERMISSIONS && validateJson(role) && JSON.parse(role) === "SUPERADMIN") || !PERMISSIONS ? (
          <>
            <div className={classes.searchInput}>
              <SearchForm handleSearch={handleSearch}></SearchForm>
            </div>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              {loading ? (
                [...Array(10)].map((num, index) => (
                  <div key={index}>
                    <Skeleton className={classes.skeleton} width={"100%"} />
                  </div>
                ))
              ) : (
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      {tableHeaders.map(
                        (tableHeader: string, index: number) => (
                          <TableCell
                            key={index}
                            className={classes.tableHeader}
                          >
                            {tableHeader}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tableBody}>
                    {admins &&
                      admins.length > 0 ?
                      admins.map((admin: any, index: number) => (
                        <AdminLogsRow
                          key={admin.id}
                          currentOpen={currentOpen}
                          setCurrentOpen={handleCurrentOpenSet}
                          admin={admin}
                          countries={countriesMapping}
                          getAdminListInfo={getAdminListInfo}
                        />
                      )) : <EmptyTable title="Admin logs" />}
                  </TableBody>
                </Table>
              )}
              {failure ? (
                <p className={classes.errorMessage}>{failure}</p>
              ) : (!admins || admins.length === 0) && !loading ? (
                <p className={classes.noDataMessage}>There is no data</p>
              ) : (
                <>
                  {admins && lastPage > 1  && admins.length > 0 && (
                    <Pagination
                      page={currentPage}
                      className={classes.pagination}
                      count={lastPage}
                      onChange={handlePaginationChange}
                    />
                  )}
                </>
              )}
            </TableContainer>
          </>
        ) : (
          <NoPermisisons></NoPermisisons>
        )}
      </>
    </DefaultLayout>
  );
};

export default AdminLogs;
