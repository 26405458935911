import { Tabs } from 'antd';
import TableWinner from "./TableWinner";
import TableReferral from './TableReferral';
import React, { useState } from 'react';
import TableWinnerLogs from './WinnerLogs';
const { TabPane } = Tabs;

function UserWinner(props: any) {
  const [activeTab, setActiveTab]=useState('1')
  const { poolDetail, isEditDisabled, search, searchDelay, rows, status, setStatus, failure, loading, currentPage, lastPage, handlePaginationChange } = props;
  const callback = (key: any) => {
    console.log(key);
    setActiveTab(key); // Update active tab
  };
  return (
    <>
      <div>
        <Tabs
          defaultActiveKey="1"
          onChange={callback}
          style={{
            minHeight: 500,
          }}
        >
          <TabPane tab="Winner" key={"1"}>
            <TableWinner poolDetail={poolDetail} isEditDisabled={isEditDisabled} search = {search} searchDelay = {searchDelay} rows = {rows} status = {status} setStatus = {setStatus} failure = {failure} loading = {loading} currentPage = {currentPage} lastPage = {lastPage} handlePaginationChange = {handlePaginationChange}/>
          </TabPane>
          <TabPane tab="Logs" key={"2"}>
            <TableWinnerLogs poolDetail={poolDetail} />
          </TabPane>
          {/* <TabPane tab="Referral" key="2">
            <TableReferral poolDetail={poolDetail} />
          </TabPane> */}
        </Tabs>
      </div>
    </>
  );
}

export default UserWinner;
