import React, {useEffect, useState} from 'react';
import useStyles from "../style";
import {isValidAddress} from "../../../services/web3";
import {renderErrorCreatePool} from "../../../utils/validate";
import {useCommonStyle} from "../../../styles";
import {etherscanRoute} from "../../../utils";
import Link from "@material-ui/core/Link";
import { NETWORK_AVAILABLE } from '../../../constants';

function AddressReceiveMoney(props: any) {
  const classes = useStyles();
  const commonStyle = useCommonStyle();
  const {
    register, setValue, errors, needValidate,
    poolDetail,watch, isEditDisabled
  } = props;
  const renderError = renderErrorCreatePool;
  const networkAvailable = watch("networkAvailable");
  const isSolanaPool = watch("supported_token_types") === NETWORK_AVAILABLE.SOLANA || networkAvailable === NETWORK_AVAILABLE.SOLANA;

  useEffect(() => {
    if (poolDetail && poolDetail.address_receiver) {
      setValue('addressReceiver', poolDetail.address_receiver);
    }
  }, [poolDetail]);

  const isDeployed = !!poolDetail?.is_deploy;

  const [defaultValue, setDefaultValue] = useState("");

  useEffect(() => {
    if(networkAvailable === "tba" || isSolanaPool) {
      setDefaultValue("")
    }
    else {
      setDefaultValue("0xbA80Cb24185EF36deb1607Ab4CA17aC1389a6957")
    }
  }, [isSolanaPool, networkAvailable])

  console.log(process.env.REACT_APP_SOLANA_NETWORK, "cluster")

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Address (Receive money)</label>
        {isDeployed &&
          <div className={commonStyle.boldText}>
            <Link href={isSolanaPool ? `${etherscanRoute(poolDetail?.address_receiver, poolDetail)}?cluster=${process.env.REACT_APP_SOLANA_NETWORK}` : etherscanRoute(poolDetail?.address_receiver, poolDetail)} target={'_blank'}>
              {poolDetail?.address_receiver}
            </Link>
          </div>
        }

        {!isDeployed &&
          <>
            <input
              type="text"
              name="addressReceiver"
              defaultValue={defaultValue}
              ref={register({
                required: needValidate,
                validate: {
                  validAddress: (val: any) => {
                    if (!needValidate) return true;
                    return isSolanaPool ? true : isValidAddress(val);
                  }
                }
              })}
              maxLength={255}
              className={classes.formControlInput}
              disabled={isDeployed || isEditDisabled}
              style = {{
                backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
              }}
            />
            <p className={classes.formErrorMessage}>
              {
                renderError(errors, 'addressReceiver')
              }
            </p>
          </>
        }
      </div>
    </>
  );
}

export default AddressReceiveMoney;
