import React from 'react'
import DefaultLayout from '../../components/Layout/DefaultLayout';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

const AdminLogsList = () => {
  return (
    <DefaultLayout>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {/*<TableCell>Id</TableCell>*/}
              <TableCell>ID</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Pool Name</TableCell>
              <TableCell>Wallet Address</TableCell>
              <TableCell>Created New User</TableCell>
              <TableCell>Edited Content</TableCell>
              <TableCell>Updated At</TableCell>
              <TableCell>Details</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody></TableBody>
        </Table>
      </TableContainer>
    </DefaultLayout>
  );
}

export default AdminLogsList