import React, { useState } from "react";
import ConfirmDialog from "../../components/Base/ConfirmDialog";
import { updateSortStatus } from "../../request/config";
import { alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";

const EnableROIForm = (props: any) => {
  const {
    isOpenEditPopup,
    setIsOpenEditPopup,
    onConfirmCalcualte,
    disableButton,
    fetchFlags,
    flag,
    sortFlag,
    setSortFlag,
    displayLabel,
    localFlag
  } = props;
  const dispatch = useDispatch();

  const [previousSortFlag, setPreviousSortFlag] = useState(sortFlag);

  const handleSubmitPopup = async () => {
    const name = flag?.name;
    try {
      const response = await updateSortStatus(
        { name, value: localFlag },
        flag?.id
      );
      fetchFlags();
      dispatch(alertSuccess("Successfull"));
      setIsOpenEditPopup(false);
      setSortFlag(response.data.value);
    } catch (e) {
      console.log(e, "error");
    }
  };

  const handleCancel = () => {
    setIsOpenEditPopup(false);
    setSortFlag(sortFlag);
  };
  return (
    <ConfirmDialog
      title={displayLabel ? "Enable ROI Sorting" : "Disable ROI Sorting"}
      open={isOpenEditPopup}
      confirmLoading={disableButton}
      onConfirm={handleSubmitPopup}
      onCancel={handleCancel}
    >
      <div>Are you sure you want to enable sort by roi?</div>
    </ConfirmDialog>
  );
};

export default EnableROIForm;
