import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import CustomButton from "./Button";
import InputField from "./TextField";
import useStyles from "./style";
import CustomAccordion from "./CustomAccordion";
import {
  apiRoute,
  convertToTransaction,
  SendSolanaTransaction,
} from "../../../utils";
import { BaseRequest } from "../../../request/Request";

export default function SetOfferedTokenRate({
  vaultAddress,
  pool_index,
  poolId,
  multiSigWallet,
  isValidVault,
  owner,
  disableButton
}: any) {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [rate, setRate] = useState(0);
  const [loading, setLoading] = useState<any>(false);
  const [tokenMint, setTokenMint] = useState("");
  const [errors, setErrors] = useState<any>({});

  const handleRateChange = (e: any) => {
    setRate(e.target.value);
    if (rate) {
      setErrors((prevErrors: any) => {
        const { rate, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  };

  const handleTokenMintChange = (e: any) => {
    setTokenMint(e.target.value);
    if (tokenMint) {
      setErrors((prevErrors: any) => {
        const { tokenMint, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  };

  useEffect(() => {
    if (rate) {
      setErrors((prevErrors: any) => {
        const { rate, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [rate]);

  useEffect(() => {
    if (rate > 0) {
      setErrors((prevErrors: any) => {
        const { rate, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [rate]);

  useEffect(() => {
    if (tokenMint) {
      setErrors((prevErrors: any) => {
        const { tokenMint, ...remainingErrors } = prevErrors;
        return remainingErrors;
      });
    }
  }, [tokenMint]);

  const offeredTokenRate = async () => {
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"));
      return;
    }
    if (!pool_index) {
      dispatch(alertFailure("Pool is not deployed"));
      return;
    }
    if (!multiSigWallet) {
      dispatch(alertFailure("Please Enter MultiSig Wallet Address"));
      return;
    }
    if (!vaultAddress) {
      dispatch(alertFailure("Please Enter Vault Address"));
      return;
    }
    if (owner && !isValidVault) {
      dispatch(alertFailure("Vault Address should be the owner of Pool"));
      return;
    }
    const errors: any = {};
    if (!rate) {
      errors.rate = "Rate is required";
    }
    if (rate <= 0) {
      errors.rate = "Rate must be greater than zero";
    }
    if (!tokenMint) {
      errors.fundingAccount = "Token Mint is required";
    }
    if (Object.keys(errors).length === 0) {
      setLoading(true);
      try {
        const squads_multi_sig_address = multiSigWallet;
        const baseRequest = new BaseRequest();
        let url = apiRoute(`/solana-multisig/set-offered-token-rate`);
        const body = {
          squads_multi_sig_address: squads_multi_sig_address,
          campaign_id: poolId,
          rate: rate,
          token_mint: tokenMint,
          fee_payer: window?.solana?.publicKey.toString(),
        };
        const response = (await baseRequest.post(url, body)) as any;
        const result = await response.json();
        const data = result.data;
        const tx = await convertToTransaction(data);
        const sig = await SendSolanaTransaction(tx);
        dispatch(alertSuccess("Transaction Successfull"));
        setTokenMint("");
        setLoading(false);
      } catch (error) {
        dispatch(alertFailure("Transaction Failed"));
        setRate(0);
        setTokenMint("");
        setLoading(false);
      }
    } else {
      setErrors(errors);
    }
  };
  return (
    <div className={classes.spaceAbove}>
      <CustomAccordion title="Set Offered Token Rate">
        <div className={classes.columnFlex}>
          <InputField
            value={rate}
            onChange={handleRateChange}
            error={errors.rate}
            label="Enter Rate"
            id="rate"
            disableButton = {disableButton}
          />
          <InputField
            value={tokenMint}
            onChange={handleTokenMintChange}
            error={errors.tokenMint}
            label="Enter Token Mint"
            id="tokenMint"
            disableButton = {disableButton}
          />
          <CustomButton disableButton = {disableButton} loading = {loading} onClick={() => offeredTokenRate()} />
        </div>
      </CustomAccordion>
    </div>
  );
}
