import React, { useEffect } from "react";
import useStyles from "../style";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { renderErrorCreatePool } from "../../../utils/validate";
import { Switch } from "antd";
import { changeFeaturePool } from "../../../request/pool";
import { alertSuccess } from "../../../store/actions/alert";
import { withRouter } from "react-router";
import { useDispatch } from "react-redux";

function FeaturedPool(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, poolDetail, isEditDisabled } = props;
  const renderError = renderErrorCreatePool;
  const dispatch = useDispatch();

  useEffect(() => {
    if (poolDetail && poolDetail.is_featured !== undefined) {
      setValue("is_featured", !!poolDetail.is_featured);
    }
  }, [poolDetail, setValue]);

  const changeFeaturedPool = async (value: any) => {
    const res = await changeFeaturePool({
      poolId: poolDetail.id,
      is_featured: value,
    });
    if (res.status === 200) {
      dispatch(alertSuccess("Pool Marked as Featured Successfully"));
    }
    return value;
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <label className={classes.formControlLabel}>Feature Pool</label>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <FormControl component="fieldset">
          <Controller
            control={control}
            name="is_featured"
            render={(field) => {
              const { value, onChange } = field;
              return (
                <Switch
                  onChange={async (switchValue) => {
                    // eslint-disable-next-line no-restricted-globals
                    if (!confirm(`Would you like to ${switchValue ? "feature" : "unfeature"} this pool?`)) {
                      return false;
                    }
                    await onChange(switchValue);
                    await changeFeaturedPool(switchValue);
                  }}
                  checked={value}
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  disabled={isEditDisabled}
                />
              );
            }}
          />

          <p className={classes.formErrorMessage}>
            {renderError(errors, "is_featured")}
          </p>
        </FormControl>
      </div>
    </div>
  );
}

export default withRouter(FeaturedPool);