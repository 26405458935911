import { GroupName, PermissionKey, PermissionLabel } from "./PermissionEnums";
import { ExtendedGroup, Permission, PermissionsData } from "./PermissionTypes";

export const generateDummyPermissions = (): {
  key: PermissionKey;
  id: number;
}[] => {
  const permissions: { key: PermissionKey; id: number }[] = [];

  Object.values(PermissionKey).forEach((key, index) => {
    permissions.push({ key, id: index + 1 });
  });

  return permissions;
};

export const groupMapping: Record<GroupName, PermissionKey[]> = {
  [GroupName.LimitedContent]: [
    PermissionKey.EditShortDescription,
    PermissionKey.EditAllDescriptionText,
    PermissionKey.EditProduct,
    PermissionKey.EditRoadmap,
    PermissionKey.EditBusinessModel,
    PermissionKey.EditTeam,
    PermissionKey.EditPartnersInvestor,
    PermissionKey.EditTokenMetrics,
    PermissionKey.EditImcValue,
    PermissionKey.EditTotalImcValue,
    PermissionKey.EditFdvValue,
    PermissionKey.LIMITED_CONTENT,
  ],
  [GroupName.ExpandedContent]: [
    PermissionKey.EditShortDescription,
    PermissionKey.EditAllDescriptionText,
    PermissionKey.EditProduct,
    PermissionKey.EditRoadmap,
    PermissionKey.EditBusinessModel,
    PermissionKey.EditTeam,
    PermissionKey.EditPartnersInvestor,
    PermissionKey.EditTokenMetrics,
    PermissionKey.EditProjectName,
    PermissionKey.EditPoolBanner,
    PermissionKey.EditWebsite,
    PermissionKey.EditRelationshipType,
    PermissionKey.EditMarketMaker,
    PermissionKey.EditMarketMakerIcon,
    PermissionKey.EditRefundTerms,
    PermissionKey.EditTokenSymbol,
    PermissionKey.EditTokenIcon,
    PermissionKey.EditCoinGeckoID,
    PermissionKey.EditATHPrice,
    PermissionKey.EditAllSocialSettings,
    PermissionKey.EXPANDED_CONTENT,
  ],
  [GroupName.Logistic]: [
    PermissionKey.Display,
    PermissionKey.LockUnlockPool,
    PermissionKey.EditKYCBpypass,
    PermissionKey.EditTotalTokenAmount,
    PermissionKey.EditPoolNetwork,
    PermissionKey.EditTokenAddress,
    PermissionKey.EditExchangeRate,
    PermissionKey.EditShowPricePerToken,
    PermissionKey.EditForbiddenCountries,
    PermissionKey.EditTimeSettingsTab,
    PermissionKey.EditUsersListTab,
    PermissionKey.TokenSoldDisplay,
    PermissionKey.LOGISTIC,
  ],
};

export const dependenciesMap: Partial<Record<PermissionKey, PermissionKey[]>> =
  {
    [PermissionKey.CloneCreateIDOPools]: [
      PermissionKey.ViewIDOPoolList,
      PermissionKey.EditShortDescription,
      PermissionKey.EditAllDescriptionText,
      PermissionKey.EditProduct,
      PermissionKey.EditRoadmap,
      PermissionKey.EditBusinessModel,
      PermissionKey.EditTeam,
      PermissionKey.EditPartnersInvestor,
      PermissionKey.EditTokenMetrics,
      PermissionKey.EditProjectName,
      PermissionKey.EditPoolBanner,
      PermissionKey.EditWebsite,
      PermissionKey.EditRelationshipType,
      PermissionKey.EditMarketMaker,
      PermissionKey.EditMarketMakerIcon,
      PermissionKey.EditRefundTerms,
      PermissionKey.EditTokenSymbol,
      PermissionKey.EditTokenIcon,
      PermissionKey.EditCoinGeckoID,
      PermissionKey.EditATHPrice,
      PermissionKey.EditAllSocialSettings,
      PermissionKey.EditTotalTokenAmount,
      PermissionKey.UpdatePoolExpanded,
      PermissionKey.TierBaseVisibility,
      PermissionKey.MinimumTierForPool,
      PermissionKey.MinimumTierForPrePurchase,
      PermissionKey.PoolType,
      PermissionKey.EXPANDED_CONTENT,
    ],
    [PermissionKey.LOGISTIC]: [
      PermissionKey.EditShortDescription,
      PermissionKey.EditAllDescriptionText,
      PermissionKey.EditProduct,
      PermissionKey.EditRoadmap,
      PermissionKey.EditBusinessModel,
      PermissionKey.EditTeam,
      PermissionKey.EditPartnersInvestor,
      PermissionKey.EditTokenMetrics,
      PermissionKey.EditProjectName,
      PermissionKey.EditPoolBanner,
      PermissionKey.EditWebsite,
      PermissionKey.EditRelationshipType,
      PermissionKey.EditMarketMaker,
      PermissionKey.EditMarketMakerIcon,
      PermissionKey.EditRefundTerms,
      PermissionKey.EditTokenSymbol,
      PermissionKey.EditTokenIcon,
      PermissionKey.EditCoinGeckoID,
      PermissionKey.EditATHPrice,
      PermissionKey.EditAllSocialSettings,
      PermissionKey.EXPANDED_CONTENT
    ],
    [PermissionKey.DeployIDOPools]: [
      PermissionKey.ViewIDOPoolList,
      PermissionKey.Display,
      PermissionKey.LockUnlockPool,
      PermissionKey.EditKYCBpypass,
      PermissionKey.EditTotalTokenAmount,
      PermissionKey.EditPoolNetwork,
      PermissionKey.EditTokenAddress,
      PermissionKey.EditExchangeRate,
      PermissionKey.EditShowPricePerToken,
      PermissionKey.EditForbiddenCountries,
      PermissionKey.EditTimeSettingsTab,
      PermissionKey.EditUsersListTab,
      PermissionKey.UpdatePoolLogistic,
      PermissionKey.EditTokenReceivingAddress,
      PermissionKey.DepositToken,
      PermissionKey.DepositCurrency,
      PermissionKey.WithdrawRemainingTokenAmount,
      PermissionKey.WithdrawRemainingRefundAmount,
      PermissionKey.DepositRefundingAmount,
      PermissionKey.LOGISTIC,
    ],
    [PermissionKey.EditTokenReceivingAddress]: [
      PermissionKey.ViewIDOPoolList,
      PermissionKey.UpdatePoolLimited,
    ],
    [PermissionKey.UpdatePoolExpanded]: [PermissionKey.ViewIDOPoolList],
    [PermissionKey.EditUserList]: [PermissionKey.ViewUserList],
    [PermissionKey.EditKYCUser]: [PermissionKey.ViewKYCUserList],
    [PermissionKey.EditStakingPools]: [PermissionKey.ViewStakingPoolsList],
    [PermissionKey.EditRPCURLList]: [PermissionKey.ViewRPCURLList],
    [PermissionKey.EditConfigPage]: [PermissionKey.ViewConfigPage],
    [PermissionKey.EditWhitelistWallets]: [PermissionKey.ViewWhitelistWallets],
    [PermissionKey.EditSolanaMultiSig]: [PermissionKey.ViewSolanaMultiSig],
    [PermissionKey.EditTierRange]: [
      PermissionKey.ViewTiersRangeList,
      PermissionKey.ViewUserList,
    ],
    [PermissionKey.TierBaseVisibility]: [
      PermissionKey.ViewIDOPoolList,
      PermissionKey.UpdatePoolExpanded,
    ],
    [PermissionKey.MinimumTierForPool]: [
      PermissionKey.ViewIDOPoolList,
      PermissionKey.UpdatePoolExpanded,
    ],
    [PermissionKey.MinimumTierForPrePurchase]: [
      PermissionKey.ViewIDOPoolList,
      PermissionKey.UpdatePoolExpanded,
    ],
    [PermissionKey.PoolType]: [
      PermissionKey.ViewIDOPoolList,
      PermissionKey.UpdatePoolExpanded,
    ],
    [PermissionKey.DepositToken]: [
      PermissionKey.ViewIDOPoolList,
    ],
    [PermissionKey.DepositRefundingAmount]: [
      PermissionKey.ViewIDOPoolList,
    ],
    [PermissionKey.DepositCurrency]: [
      PermissionKey.ViewIDOPoolList,
    ],
    [PermissionKey.WithdrawRemainingRefundAmount]: [
      PermissionKey.ViewIDOPoolList,
    ],
    [PermissionKey.WithdrawRemainingTokenAmount]: [
      PermissionKey.ViewIDOPoolList,
    ],
  };

export const getPermissionLabel = (key: PermissionKey): string => {
  return PermissionLabel[key];
};

export const getCheckedPermissionIds = (
  checkedPermissions: { [key in PermissionKey]?: boolean },
  permissions: PermissionsData
): number[] => {
  const checkedIds: number[] = [];

  Object.entries(checkedPermissions).forEach(([key, isChecked]) => {
    if (isChecked) {
      const permission = permissions.groups
        .flatMap((group) => group.permissions)
        .concat(permissions.singlePermissions)
        .find((p) => p.name === key);

      if (permission) {
        checkedIds.push(permission.id);
      }
    }
  });

  return checkedIds;
};

export const organizePermissions = (
  permissions: { name: PermissionKey; id: number }[]
): PermissionsData => {
  const groupedPermissions: PermissionsData = {
    groups: [
      {
        name: GroupName.LimitedContent,
        permissions: [],
        encounteredKeys: new Set<PermissionKey>(),
      },
      {
        name: GroupName.ExpandedContent,
        permissions: [],
        encounteredKeys: new Set<PermissionKey>(),
      },
      {
        name: GroupName.Logistic,
        permissions: [],
        encounteredKeys: new Set<PermissionKey>(),
      },
    ],
    singlePermissions: [],
  };

  if(permissions?.length > 0) {
    permissions.forEach((permission) => {
      const matchedGroups = groupedPermissions.groups.filter((g) =>
        groupMapping[g.name as GroupName].includes(permission.name)
      );
      if (matchedGroups.length > 0) {
        matchedGroups.forEach((group: ExtendedGroup) => {
          if (!group.encounteredKeys.has(permission.name)) {
            group.permissions.push({
              ...permission,
              label: getPermissionLabel(permission.name),
            });
            group.encounteredKeys.add(permission.name);
          }
        });
      } else {
        const dependencies = dependenciesMap[permission.name];
        if (dependencies) {
          const singlePermissionWithDependencies: Permission = {
            ...permission,
            label: getPermissionLabel(permission.name),
            dependencies: dependencies,
          };
          groupedPermissions.singlePermissions.push(
            singlePermissionWithDependencies
          );
        } else {
          groupedPermissions.singlePermissions.push({
            ...permission,
            label: PermissionLabel[permission.name],
          });
        }
      }
    });
  }

  return groupedPermissions;
};

export const findPermissionInOtherGroup = (
  permissionKey: PermissionKey,
  currentGroupName: GroupName,
  checkedGroups: { [key in GroupName]?: boolean },
  permissions: PermissionsData
): PermissionKey[] => {
  const otherGroups = permissions.groups.filter(
    (group) =>
      group.name !== currentGroupName && checkedGroups[group.name as GroupName]
  );

  const permissionsInOtherGroups: PermissionKey[] = [];
  otherGroups.forEach((group) => {
    group.permissions.forEach((permission) => {
      if (permission.name === permissionKey) {
        permissionsInOtherGroups.push(permission.name);
      }
    });
  });

  return permissionsInOtherGroups;
};

export const getAllPermissionKeys = (
  permissions: PermissionsData
): PermissionKey[] => {
  const groupPermissions = permissions.groups.flatMap((group) =>
    group.permissions.filter((permission) => permission.label !== undefined)
    .map((permission) => permission.name)
  );
  const singlePermissions = permissions.singlePermissions
  .filter((permission) => permission.label !== undefined)
  .map((permission) => permission.name);
  return Array.from(new Set([...groupPermissions, ...singlePermissions]));
};
