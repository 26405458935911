import React, {useEffect, useState} from 'react';
import useStyles from "../style";
import FormControl from '@material-ui/core/FormControl';
import {Controller} from "react-hook-form";
import {renderErrorCreatePool} from "../../../utils/validate";
import {Switch} from 'antd';
import {changeLockStatus} from "../../../request/pool";
import {alertSuccess} from "../../../store/actions/alert";
import {withRouter} from "react-router";
import {useDispatch} from "react-redux";

function LockPool(props: any) {
  const classes = useStyles();
  const {
    setValue, errors, control,
    poolDetail,
    watch,
    isEditDisabled
  } = props;
  const renderError = renderErrorCreatePool;
  const dispatch = useDispatch();

  useEffect(() => {
    if (poolDetail && (poolDetail.is_display != undefined)) {
      setValue('is_locked', !!poolDetail.is_locked);
    }
  }, [poolDetail]);

  const changeDisplay = async (value: any) => {
    const res = await changeLockStatus({
      poolId: poolDetail.id,
      is_locked: value,
    });
    console.log('Change display: Response: ', res);
    if (res.status === 200) {
      dispatch(alertSuccess('Change display setting successful!'));
    }
    return value;
  };

  const isLocked = watch("is_locked")

  return (
    <>
      <div><label className={classes.formControlLabel}>Lock Pool</label></div>
      <div style={{color: 'red'}}>Users will not see Campaigns while the campaign is locked</div>
      <FormControl component="fieldset">
        <Controller
          control={control}
          name="is_locked"
          render={(field) => {
            const { value, onChange } = field;
            return (
              <Switch
                onChange={ async (switchValue) => {
                  // eslint-disable-next-line no-restricted-globals
                  if (!confirm(`Do you want to ${isLocked ? 'Un lock' : 'lock'} pool?`)) {
                    return false;
                  }
                  await onChange(switchValue);
                  await changeDisplay(switchValue);
                }}
                checked={value}
                checkedChildren="Lock"
                unCheckedChildren="Un Lock"
                disabled={isEditDisabled}
              />
            )
          }}
        />

        <p className={classes.formErrorMessage}>
          {
            renderError(errors, 'is_locked')
          }
        </p>
      </FormControl>
      <br/>
    </>
  );
}

export default withRouter(LockPool);
