import { Button } from "@material-ui/core";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { alertFailure, alertSuccess } from "../../../../store/actions/alert";
import React from "react";
import { PublicKey } from "@solana/web3.js";
import { apiRoute, convertToTransaction, SendSolanaTransaction } from "../../../../utils";
import { BaseRequest } from "../../../../request/Request";

function SolanaWithdrawButton(props: any) {
  const dispatch = useDispatch();
  const { poolDetail, disabled } = props;
  const [loading, setLoading] = useState(false);
  const [transactionHash, setTransactionHash] = useState<any>();
  const [disableButton, setDisableButton] = useState<Boolean>(false);

  const withdrawRefundBalance = async () => {
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure(`Please Connect your Solana Wallet`));
      return;
    }
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Do you want withdraw refund balance?")) {
      return false;
    }
    try {
      setLoading(true);
      setDisableButton(true);
      const receiving_token_mint_: any =
        poolDetail?.accept_currency === "usdt"
          ? process.env.REACT_APP_SOLANA_USDT_ADDRESS
          : process.env.REACT_APP_SOLANA_USDC_ADDRESS;

      const receiving_token_mint: PublicKey = new PublicKey(
        receiving_token_mint_
      );
      const receiving_token_account: PublicKey = new PublicKey(
        poolDetail?.address_receiver
      );

      try {
        const baseRequest = new BaseRequest();
        let url = apiRoute(`/solana/refund-remaining-token-currency`);
        const body = {
          campaign_id: poolDetail?.id,
          currency: receiving_token_mint,
          wallet: receiving_token_account,
          fee_payer: window?.solana?.publicKey.toString(),
        };
        const response = (await baseRequest.post(url, body)) as any;
        const data = await response.json();
        const tx = await convertToTransaction(data?.data);
        const sig = await SendSolanaTransaction(tx);
        console.log("Refund tokens successful.", sig);
      } catch (err) {
        console.log(err);
      }

      setLoading(false);
      dispatch(alertSuccess("Withdraw Success !!!"));
    } catch (e) {
      setDisableButton(false);
      setLoading(false);
      dispatch(alertFailure("Withdraw Fail !!!"));
      return false;
    }
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={withdrawRefundBalance}
        disabled={disabled || disableButton}
      >
        Withdraw All Refunding Amount
      </Button>

      {loading && <div>Loading....</div>}
      {transactionHash && <div>Transaction Hash: {transactionHash}</div>}
    </>
  );
}

export default SolanaWithdrawButton;
