import React, { useEffect } from "react";
import useStyles from "../style";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { Controller } from "react-hook-form";
import { getTiers } from "../../../request/tier";
import { renderErrorCreatePool } from "../../../utils/validate";

function SolanaAcceptCurrency(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, watch, poolDetail, isEditDisabled } = props;
  const renderError = renderErrorCreatePool;

  useEffect(() => {
    if (poolDetail && poolDetail.accept_currency) {
      setValue("acceptCurrency", poolDetail.accept_currency);
    }
  }, [poolDetail]);

  useEffect(() => {
    if (poolDetail && poolDetail.id) {
      getTiers(poolDetail.id).then((res) => {});
    }
  }, [poolDetail]);

  const isDeployed = !!poolDetail?.is_deploy;
  const networkAvailable = watch("networkAvailable");

  return (
    <>
      <div className={classes.formControl}>
        <FormControl component="fieldset">
          <label className={classes.formControlLabel}>Accepted Currency</label>

          <Controller
            rules={{ required: true }}
            control={control}
            defaultValue={poolDetail?.accept_currency || "usdt"}
            name="acceptCurrency"
            as={
              <RadioGroup row>
                <FormControlLabel
                  value={"usdt"}
                  control={<Radio />}
                  label={"USDT"}
                  disabled={isDeployed || isEditDisabled}
                />
                <FormControlLabel
                  value={"usdc"}
                  control={<Radio />}
                  label={"USDC"}
                  disabled={isDeployed || isEditDisabled}
                />
              </RadioGroup>
            }
          />
          <p className={classes.formErrorMessage}>
            {renderError(errors, "acceptCurrency")}
          </p>
        </FormControl>
      </div>
    </>
  );
}

export default SolanaAcceptCurrency;
