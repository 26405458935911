import {
    SOLANA_CONNECT_WALLET,
    SOLANA_DISCONNECT_WALLET,
    SOLANA_SET_WALLET_ADDRESS,
    SOLANA_LOGOUT,
  } from "../constants/solana-wallet";
  
  const initialState = {
    isConnected: false,
    walletAddress: "",
  };
  
  const solanaWalletReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case SOLANA_CONNECT_WALLET:
        return {
          ...state,
          isConnected: true,
        };
      case SOLANA_DISCONNECT_WALLET:
        return {
          ...state,
          isConnected: false,
          walletAddress: "",
        };
      case SOLANA_SET_WALLET_ADDRESS:
        return {
          ...state,
          walletAddress: action.payload,
        };
      case SOLANA_LOGOUT:
        return {
          ...state,
          isConnected: false,
          walletAddress: "",
        };
      default:
        return state;
    }
  };
  
  export default solanaWalletReducer;
  