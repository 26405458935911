import React from 'react'
import ConfirmDialog from '../../../../components/Base/ConfirmDialog';
import useStyles from '../../style';

const UserDeleteParticipants = (props: any) => {
    const classes = useStyles();
    const {isOpenEditPopup, setIsOpenEditPopup, handleDeleteParticipant, deleteParticipant, setDeleteParticipant} = props;
  return (
    <>
      <ConfirmDialog
        title={"Enter Password"}
        open={isOpenEditPopup}
        onConfirm={handleDeleteParticipant}
        onCancel={() => {
          setIsOpenEditPopup(false);
          setDeleteParticipant("");
        }}
        confirmLoading={false}
      >
        <div className={classes.formControl}>
          <label className={classes.formControlLabel}>Password</label>
          <div>
            <input
              placeholder="Please enter password"
              className={`${classes.formInputBox}`}
              style={{ width: "100%" }}
              type="password"
              value={deleteParticipant}
              onChange={(e: any) => setDeleteParticipant(e.target.value)}
              autoComplete='off'
              // disabled={isDeployed}
            />
          </div>
        </div>
      </ConfirmDialog>
    </>
  );
}

export default UserDeleteParticipants