import { BaseRequest } from "./../request/Request"
import { EXPORT_USER_TYPE } from "./../constants"
import fileDownload from "js-file-download"
import moment from "moment"

export function objectToQueryParam(obj: Object | any) {
  let params = ""
  Object.keys(obj).forEach((item: any, index: any) => {
    params += item + "=" + obj[item]
    if (index !== Object.keys(obj).length - 1) {
      params += "&"
    }
  })
  return params
}

export function truncateText(text:string, maxLength:number) {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + "...";
  }
  return text;
}

export function truncateTextBetween(text: string) {
  if (text && text.length > 15) {
    return `${text.substring(0, 20)}****${text.substring(text.length - 10)}`;
  }
  return text;
}
export const exportParticipants = async (campaignId: any, data: any = {}) => {
  const baseRequest = new BaseRequest();
  let url = process.env.REACT_APP_API_BASE_URL +
    `/admin/users/download?campaign_id=${campaignId}&type=${EXPORT_USER_TYPE.USER_PARTICIPANT}`
  const fileData = (await baseRequest.postDownload(url, data)) as any;
  fileDownload(
    fileData,
    `user_participant_${campaignId}_${moment().format("DD_MM_YYYY")}.csv`
  );
};
export const importParticipants = async (poolId: number, file: any) => {
  const baseRequest = new BaseRequest();
  var form_data = new FormData();
  form_data.append('participants', file);
  let url = process.env.REACT_APP_API_BASE_URL + `/admin/pool/${poolId}/participants/import`

  const response = await baseRequest.postImage(url, form_data) as any;
  const resObject = await response.json();
  return resObject;
}

export const importWinners = async (poolId: number, file: any) => {
  const baseRequest = new BaseRequest();
  var form_data = new FormData();
  form_data.append('importCustomWinners', file);
  let url = `/admin/pool/import-custom-winners/${poolId}`

  const response = await baseRequest.putImage(url, form_data) as any;
  const resObject = await response.json();
  return resObject;
}

export function removeHtmlTags(inputString:string) {
  const regex = /<[^>]*>/g;
  return inputString.replace(regex, '');
}