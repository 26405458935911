import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { FormControl, Switch } from "@material-ui/core";
import { alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";
import { getFlag, updateSortStatus } from "../../request/config";
import EnableROIForm from "./EnableROIForm";
import EnableSolanaFeatureForm from "./EnableSolanaFeatureForm";
import { UseCheckPermissions } from "../../utils/useCheckPermissions";
import NoPermisisons from "../../components/Base/NoPermissions";
import EnableCurrentROIForm from "./EnableCurrentROI";
import EnableMaxCap from "./EnableMaxCapForm";
import EnableKycForm from "./EnableKycForm";
import { MAX_CAP } from "../../constants";

const Config = () => {
  const dispatch = useDispatch();
  const [sortFlag, setSortFlag] = useState(true);
  const [flag, setFlag] = useState("");
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false);
  const [displayLabel, setDisplayLabel] = useState(true);
  const [localFlag, setLocalFlag] = useState(true);

  const [solnaFeatFlag, setSolanaFeatFlag] = useState(true);
  const [solanaFlag, setSolanaFlag] = useState("");
  const [isOpenEditPopupSolana, setIsOpenEditPopupSolana] =
    useState<boolean>(false);
  const [displaySoalnaLabel, setDisplaySolanaLabel] = useState(true);
  const [localSolanaFlag, setLocalSolanaFlag] = useState(true);

  const handleSwitchChange = async (event: any) => {
    const { checked } = event?.target;
    const value: any = checked;
    // setSortFlag(value);
    setLocalFlag(value);
    setIsOpenEditPopup(true);
    setDisplayLabel(checked);
  };

  const handleSolanaSwitchChange = async (event: any) => {
    const { checked } = event?.target;
    const value: any = checked;
    // setSortFlag(value);
    setLocalSolanaFlag(value);
    setIsOpenEditPopupSolana(true);
    setDisplaySolanaLabel(checked);
  };

  const fetchFlags = async () => {
    try {
      const response = await getFlag();
      const flags = response.data;
      const solanaFlag = flags?.find((flag: any) => flag?.name === "solana");
      const sortFlag = flags?.find(
        (flag: any) => flag?.name === "COMPLETED_POOL_ROI_SORT_ENABLED"
      );
      if (response?.status === 200) {
        setFlag(sortFlag);
        setSortFlag(sortFlag.value);
        setSolanaFlag(solanaFlag);
        setSolanaFeatFlag(solanaFlag.value);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchFlags();
  }, []);

  const isViewConfigPage = UseCheckPermissions("VIEW_CONFIG_SECTION");
  const isEditConfigPage = UseCheckPermissions("EDIT_CONFIG_SECTION");
  return (
    <DefaultLayout>
      {isViewConfigPage ? (
        <div>
          <EnableROIForm
            fetchFlags={fetchFlags}
            flag={flag}
            isOpenEditPopup={isOpenEditPopup}
            setIsOpenEditPopup={setIsOpenEditPopup}
            sortFlag={sortFlag}
            setSortFlag={setSortFlag}
            displayLabel={displayLabel}
            localFlag={localFlag}
          />
          <EnableSolanaFeatureForm
            fetchFlags={fetchFlags}
            solanaFlag={solanaFlag}
            isOpenEditPopupSolana={isOpenEditPopupSolana}
            setIsOpenEditPopupSolana={setIsOpenEditPopupSolana}
            solnaFeatFlag={solnaFeatFlag}
            setSolanaFeatFlag={setSolanaFeatFlag}
            displaySoalnaLabel={displaySoalnaLabel}
            localSolanaFlag={localSolanaFlag}
          />

          <EnableCurrentROIForm />
          {MAX_CAP && <EnableMaxCap />}
          <EnableKycForm />

          <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
            <Switch
              onChange={(e) => handleSwitchChange(e)}
              checked={sortFlag ? true : false}
              disabled={!isEditConfigPage}
            />
            <h4
              style={{
                marginRight: "10px",
                marginTop: "5px",
                fontSize: "14px",
              }}
            >
              Enable ROI Sorting
            </h4>
          </FormControl>
          <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
            <Switch
              onChange={(e) => handleSolanaSwitchChange(e)}
              checked={solnaFeatFlag ? true : false}
              disabled={!isEditConfigPage}
            />
            <h4
              style={{
                marginRight: "10px",
                marginTop: "5px",
                fontSize: "14px",
              }}
            >
              Enable Solana Feature
            </h4>
          </FormControl>
        </div>
      ) : (
        <NoPermisisons />
      )}
    </DefaultLayout>
  );
};

export default Config;
