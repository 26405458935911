import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Transition from "../../components/Base/Transition";
import useStyles from "../../components/Base/ConfirmDialog/style";
import { updateTierStatus } from "../../request/tiers-range";
import { useDispatch } from "react-redux";
import { alertSuccess } from "../../store/actions/alert";

const EnableTiersForm = (props: any) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const {
    isOpenEditTierPopup,
    setIsOpenEditTierPopup,
    getFlag,
    tierFlag,
    setTierFlag,
    displayLabel,
    localTierFlag,
    setEnableTierLoading,
    enableTierLoading,
  } = props;

  const handleSubmitPopup = async () => {
    setEnableTierLoading(true);
    const tier = getFlag?.name;
    try {
      const response = await updateTierStatus(
        { tier, value: localTierFlag },
        getFlag.id
      );
      dispatch(alertSuccess("Successfull"));
      setIsOpenEditTierPopup(false);
      setTierFlag(response.data.value);
      setEnableTierLoading(false);
    } catch (e) {
      dispatch(alertSuccess("Something Went Wrong"));
      setEnableTierLoading(false);
    }
  };

  const handleCancel = () => {
    setIsOpenEditTierPopup(false);
    setTierFlag(tierFlag);
  };
  return (
    <Dialog
      open={isOpenEditTierPopup}
      TransitionComponent={Transition}
      aria-labelledby="form-dialog-title"
      className={styles.dialog}
    >
      <DialogTitle id="form-dialog-title">
        {!displayLabel ? "Enable Tiers" : "Disable Tiers"}
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        Are you sure to apply these tiers in existing system
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button
          className={styles.dialogButton}
          color="primary"
          onClick={handleSubmitPopup}
          disabled={enableTierLoading}
        >
          {enableTierLoading ? <div className={styles.loader}></div> : "Submit"}
        </Button>
        <Button
          className={`${styles.dialogButton} ${styles.dialogButtonCancel}`}
          onClick={() => handleCancel()}
          color="primary"
          disabled={enableTierLoading}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EnableTiersForm;
