import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  function: {
    marginTop: "20px",
  },
  center: {
    textAlign: "center",
  }
}));

export default useStyles;
