import React, { useEffect, useState } from "react";
import useStyles from "../style";
import { Controller } from "react-hook-form";
import { DatePicker } from "antd";
import { useDispatch } from "react-redux";
import moment from "moment";
import { DATETIME_FORMAT } from "../../../constants";
import { withRouter } from "react-router";

function DisplayScheduleTime(props: any) {
  const classes = useStyles();
  const { setValue, errors, control, poolDetail, isEditDisabled, watch } = props;
  const renderError = (errors: any, name: string) => errors[name]?.message || '';
  const dispatch = useDispatch();
  
  const [datePickerOpen, setDatePickerOpen] = useState(false); // State to control DatePicker

  useEffect(() => {
    if (poolDetail && poolDetail.schedule_time) {
      // Set the initial schedule time when the component loads
      const scheduleTime = moment.unix(poolDetail.schedule_time);
      setValue("schedule_time", scheduleTime);
    }
  }, [poolDetail, setValue]);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <div className={classes.formControlFlexBlock}>
          <label className={classes.formControlLabel}>
            Pool Visibility Scheduled Time
          </label>
          <div>
            <Controller
              control={control}
              name="schedule_time"
              defaultValue={null} // Default value can be null initially
              render={( field ) => { 
                const {value,onChange} = field
                return <DatePicker
                  value={value ? moment(value) : null} // Make sure value is a moment object
                  open={datePickerOpen}
                  format="YYYY-MM-DD HH:mm:ss"
                  onChange={(date) => {
                    onChange(date); // Update the form value
                    setValue("schedule_time", date); // Ensure form state is updated
                  }}
                  showTime={{
                    defaultValue: moment("00:00:00", "HH:mm:ss"),
                    format: "HH:mm",
                  }}
                  onOk={() => setDatePickerOpen(false)} // Close picker when the OK button is clicked
                  onOpenChange={setDatePickerOpen} // Handle opening/closing state
                  disabledDate={(current: any) => current && current < moment().startOf("day")}
                  disabledTime={(current: any) => {
                    if (current && current.isSame(moment(), "day")) {
                      const currentHour = moment().hour();
                      const currentMinute = moment().minute();
                      return {
                        disabledHours: () =>
                          Array.from(Array(24).keys()).filter((hour) => hour < currentHour),
                        disabledMinutes: (selectedHour: any) =>
                          selectedHour === currentHour
                            ? Array.from(Array(60).keys()).filter(
                                (minute) => minute < currentMinute
                              )
                            : [],
                      };
                    }
                    return {};
                  }}
                  minuteStep={15}
                />
              }}
            />
          </div>
          <div className={`${classes.formErrorMessage} ${classes.formErrorMessageAbsolute}`}>
            {renderError(errors, "schedule_time")}
          </div>
        </div>
      </div>
    </>
  );
}

export default withRouter(DisplayScheduleTime);
