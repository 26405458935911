import React, { useState, useEffect } from "react";
import ConfirmDialog from "../../components/Base/ConfirmDialog";
import { updateCurrentROIStatus, getFlag } from "../../request/config";
import { alertSuccess } from "../../store/actions/alert";
import { useDispatch } from "react-redux";
import { FormControl, Switch } from "@material-ui/core";

const EnableCurrentROIForm = () => {
  const dispatch = useDispatch();
  const [currentROIFeatFlag, setCurrentROIFeatFlag] = useState(true);
  const [currentROIFlag, setCurrentROIFlag] = useState<any>("");
  const [isOpenEditPopupCurrentROI, setIsOpenEditPopupCurrentROI] = useState(false);
  const [displayCurrentROILabel, setDisplayCurrentROILabel] = useState(true);
  const [localCurrentROIFlag, setLocalCurrentROIFlag] = useState(true);

  useEffect(() => {
    fetchFlags();
  }, []);

  const fetchFlags = async () => {
    try {
      const response = await getFlag();
      const flags = response.data;
      const currentROIFlag = flags?.find((flag:any) => flag?.name === "hide-current-roi");

      if (response?.status === 200) {
        setCurrentROIFlag(currentROIFlag);
        setCurrentROIFeatFlag(currentROIFlag.value);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCurrentROISwitchChange = (event:any) => {
    const { checked } = event?.target;
    setLocalCurrentROIFlag(checked);
    setIsOpenEditPopupCurrentROI(true);
    setDisplayCurrentROILabel(checked);
  };

  const handleSubmitPopup = async () => {
    const name = currentROIFlag?.name;
    try {
      const response = await updateCurrentROIStatus(
        { name, value: localCurrentROIFlag },
        currentROIFlag?.id
      );
      fetchFlags();
      dispatch(alertSuccess("Successfull"));
      setIsOpenEditPopupCurrentROI(false);
      setCurrentROIFeatFlag(response.data.value);
    } catch (e) {
      console.log(e, "error");
    }
  };

  const handleCancel = () => {
    setIsOpenEditPopupCurrentROI(false);
    setLocalCurrentROIFlag(currentROIFeatFlag);
  };

  return (
    <>
      <FormControl style={{ flexDirection: "row", alignItems: "center" }}>
        <Switch
          onChange={handleCurrentROISwitchChange}
          checked={currentROIFeatFlag}
        />
        <h4 style={{ marginRight: "10px", marginTop: "5px", fontSize: "14px" }}>
          Enable Current ROI
        </h4>
      </FormControl>
      <ConfirmDialog
        title={displayCurrentROILabel ? "Enable Current ROI" : "Disable Current ROI"}
        open={isOpenEditPopupCurrentROI}
        confirmLoading={false}
        onConfirm={handleSubmitPopup}
        onCancel={handleCancel}
      >
        <div>Are you sure you want to {displayCurrentROILabel ? "enable" : "disable"} Current ROI?</div>
      </ConfirmDialog>
    </>
  );
};

export default EnableCurrentROIForm;
