import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { updateTier } from "../../request/tiers-range";
import useStyles from "../../components/Base/ConfirmDialog/style";

const TiersForm = (props: any) => {
  const history = useHistory();
  const styles = useStyles();
  const [minEdit, setMinEdit] = useState<any>();
  const [fcfsEdit, setFcfsEdit] = useState<any>();
  const [tierMultiplierEdit, setTierMultiplierEdit] = useState<any>();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { points, fcfs, tier_multiplier, tier, tiers } = props?.location?.state;

  let { id } : any = useParams();

  const fetchData = async (id: number) => {
    setMinEdit(points);
    setTierMultiplierEdit(tier_multiplier);
    setFcfsEdit(fcfs)
  };
  const tierName: any = {
    0: "-",
    1: "Bronze",
    2: "Silver",
    3: "Gold",
    4: "Diamond",
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setLoading(true);
    event.preventDefault();
    let upperTier;
    let lowerTier;
    for(let i = 0; i < tiers.length; i++) {
      upperTier = tiers[tier.tier - 1];
      lowerTier = tiers[tier.tier + 1];
    }
    let error = "";
    if (
      Number(minEdit) <= upperTier?.points
    ) {
      error = `Points must be greater then ${tierName[upperTier.tier]} tier`;
      setError(error);
    } else if (Number(minEdit) >= lowerTier?.points) {
      error = `Points must be less then ${tierName[lowerTier.tier]} tier`;
      setError(error);
    } else {
    let response;
    try {
      if (id) {
        response = await updateTier(
          {
            points: minEdit,
            fcfs_multiplier: fcfsEdit,
            tier_base_multiplier: tierMultiplierEdit,
          },
          id
        );
      }
      if (response?.status === 200) {
        history.push("/dashboard/tiers-range");
      }
      setLoading(true);
    } catch (error) {
      setLoading(true);
    } }
  };

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    setMinEdit(event.target.value);
  };

  const handleFCFSChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    setFcfsEdit(event.target.value);
  };

  const handleTierBaseChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("")
    setTierMultiplierEdit(event.target.value);
  };

  useEffect(() => {
    if (id) {
      fetchData(id);
    }
  }, [id]);

  return (
    <DefaultLayout>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <TextField
            label="points"
            type="number"
            id="url"
            variant="outlined"
            margin="normal"
            fullWidth
            value={minEdit}
            onChange={handleMinChange}
            error={!!error}
          />
        </div>
        {error && <div style={{ color: "red" }}>{error}</div>}
        <div>
          <TextField
            label="FCFS Multiplier"
            type="number"
            id="fcfs"
            variant="outlined"
            margin="normal"
            fullWidth
            value={fcfsEdit}
            onChange={handleFCFSChange}
          />
        </div>
        <div>
          <TextField
            label="Guaranted Multiplier"
            type="number"
            id="gurantedMultiplier"
            variant="outlined"
            margin="normal"
            fullWidth
            value={tierMultiplierEdit}
            onChange={handleTierBaseChange}
          />
        </div>
        <Button
          variant="contained"
          color="primary"
          style={{ marginRight: "10px" }}
          onClick={() => {
            history.push("/dashboard/tiers-range");
          }}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" color="primary" disabled = {loading}>
          {loading ? <div className={styles.loader}></div> :  "Submit"}
        </Button>
      </form>
    </DefaultLayout>
  );
};

export default TiersForm;
