import { BaseRequest } from "./Request";
import { apiRoute } from "../utils";
const queryString = require("query-string");

export const getAdminList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admins`);
  url += "?" + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getAdminDetail = async (id: number | string) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admins/${id}`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const updateAdmin = async (id: number | string, data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admins/${id}`);
  const response = (await baseRequest.put(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const createAdmin = async (data: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/admins`);

  const response = (await baseRequest.post(url, data)) as any;
  const resObject = await response.json();

  return resObject;
};

export const deleteAdmin = async (id: any) => {
  console.log(id, "id");
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/delete-admin`);
  const response = (await baseRequest.delete(url, { id: [id] })) as any;
  const resObject = await response.json();

  return resObject;
};

export const getAdminPermission= async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/permissions`);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};
