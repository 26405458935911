import axios from "axios";
import React, { ChangeEvent, DragEvent, useRef, useState } from "react";
import { uploadFile } from "../../../request/pool";
import useStyles from "./UserWinner/style_winner";
import { useParams } from "react-router";

interface ImageUploaderProps {
  initialSrc?: string;
  onFileSelect: (file: File) => void;
  width: any;
  value?: any;
  poolId?:string
  disabled?:any
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
  initialSrc,
  onFileSelect,
  width,
  value,
  poolId,
  disabled
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isDragOver, setIsDragOver] = useState<boolean>(false);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const classesWinner = useStyles();
  const { id } = useParams() as any;

  // Load initialSrc into selectedImage when component mounts
  React.useEffect(() => {
    if (initialSrc) {
      setSelectedImage(initialSrc);
    }
  }, [initialSrc]);

  const onDragOver = (event: DragEvent<HTMLDivElement>) => {
    if(!disabled) {
      event.preventDefault();
      setIsDragOver(true);
    }
  };

  const onDragLeave = () => {
    if(!disabled) {
      setIsDragOver(false);
    }
  };

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    if(!disabled) {
      event.preventDefault();
      setIsDragOver(false); // Reset drag over style
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      const file = event.dataTransfer.files[0];
      if (file.type.startsWith("video/")) {
        setErrorMessage(
          "Unsupported file format. Please upload a JPEG, PNG, or JPG file."
        );
        setSelectedImage(null);
      } else {
        setErrorMessage("");
        updateImage(file);
        onFileSelect(file);
        uploadImage(file,poolId)
        .then((uploadedImageUrl: any) => {
            onFileSelect(uploadedImageUrl);
          })
          .catch((error) => console.error("Upload failed:", error));
      }
    }
    }
  };

  const updateImage = (file: File) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const uploadImage = (blobInfo : any,poolId : any) => {
    return new Promise(async (resolve, reject) => {
      setImageUploadLoading(true);
      const formData = new FormData();
      formData.append("file", blobInfo);

      await uploadFile(blobInfo, poolId).then(resolve).catch(reject);
      setImageUploadLoading(false);
    });
  };

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (file.type.startsWith("video/")) {
        setErrorMessage(
          "Unsupported file format. Please upload a JPEG, PNG, or JPG file."
        );
        setSelectedImage(null);
      } else {
        setErrorMessage("");
        updateImage(file);
        onFileSelect(file);
        uploadImage(file,poolId)
        .then((uploadedImageUrl: any) => {
            onFileSelect(uploadedImageUrl);
          })
          .catch((error) => console.error("Upload failed:", error));
      }
    }
  };

  const triggerFileSelect = () => fileInputRef.current?.click();

  const renderContent = () => {
    if (selectedImage || value) {
      return (
        <>
          {imageUploadLoading ? (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className={classesWinner.loader}></div>{" "}
              <span style={{ marginLeft: "5px" }}>Uploading</span>
            </div>
          ) : (
            <img
              src={selectedImage ? selectedImage : value}
              alt="Selected"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          )}
        </>
      );
    } else {
      return (
        <div style={{ padding: "20px" }}>
          <i className="display-4 text-muted bx bx-cloud-upload" />
          <p>
            Drop image here, <br />
            or Upload
          </p>
        </div>
      );
    }
  };

  return (
    <div>
      <div
        onDragOver={onDragOver}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
        onClick={triggerFileSelect}
        style={{
          border: "1px dashed gray",
          padding: "10px",
          textAlign: "center",
          cursor: disabled ? "not-allowed" : "pointer",
          backgroundColor: isDragOver ? "#e9e9e9" : "transparent", // Change color on drag over
          width: width,
        }}
      >
        {renderContent()}
        <input
          type="file"
          onChange={onChange}
          ref={fileInputRef}
          style={{ display: "none" }}
          accept=".jpg,.jpeg,.png"
          disabled = {disabled}
        />
      </div>
      {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
    </div>
  );
};

export default ImageUploader;
