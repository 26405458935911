import { menuActions } from "./../constants/nav";
// reducers/menuReducer.ts
import { AnyAction } from "redux";

type StateType = {
  menu: number | null;
  subMenu: number | null;
};

const initialState: StateType = {
  menu: null,
  subMenu: null,
};

export const menuReducer = (
  state = initialState,
  action: AnyAction
): StateType => {
  switch (action.type) {
    case menuActions.SET_MENU:
      return { menu: action.payload, subMenu: null };
    case menuActions.SET_SUBMENU:
      return {
        menu: action.payload.menuIndex,
        subMenu: action.payload.subMenuIndex,
      };
    case menuActions.RESET_MENU:
      return initialState;
    default:
      return state;
  }
};
