import React from "react";
import useStyles from "../style";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { ACCEPT_NETWORKS, CHAIN_ID_NAME_MAPPING } from "../../../constants";
import { alertFailure, alertSuccess } from "../../../store/actions/alert";
import { useDispatch, useSelector } from "react-redux";
import { getWhiteListWallets } from "../../../request/white-list-wallets";
import TransferOwnershipForm from "./TransferOwnershipForm";
import {
  apiRoute,
  convertToTransaction,
  getPoolMetadata,
  SendSolanaTransaction,
} from "../../../utils";
import { BaseRequest } from "../../../request/Request";

const SolanaTransferOwnership = (props: any) => {
  const classes = useStyles();
  const { poolDetail, watch } = props;
  const { data: loginUser } = useTypedSelector((state: any) => state.user);
  const [loading, setLoading] = useState(false);
  const [transactionHash, setTransactionHash] = useState<any>();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [address, setAddress] = useState<any>("");
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [message, setMessage] = useState("");
  const [whiteListWallets, setWhiteListWallets] = useState<any>([]);
  const [isOpenEditPopup, setIsOpenEditPopup] = useState<boolean>(false);
  const [displayOwner, setDisplayOwner] = useState("");
  const [error, setError] = useState("");
  const isDeployed = !!poolDetail?.is_deploy;
  const networkAvailable = watch("networkAvailable");
  const { userCurrentNetwork } = useSelector((state: any) => state);
  const currentNetworkId = userCurrentNetwork?.currentNetworkId;
  const [acceptedNetwork, setAcceptedNetwork] = useState<any>(false);

  useEffect(() => {
    if (currentNetworkId === "") {
      setAcceptedNetwork(false);
    } else if (Object.values(ACCEPT_NETWORKS).includes(currentNetworkId)) {
      setAcceptedNetwork(false);
    } else {
      setAcceptedNetwork(true);
    }
  }, [currentNetworkId]);

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const response = await getWhiteListWallets();
      if (response?.status === 200) {
        console.log("success", response);
        setWhiteListWallets(response?.data);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const getOwner = async () => {
      if (!poolDetail) return;

      if (!window?.solana?.publicKey) return;
      try {
        const poolData = await getPoolMetadata(poolDetail?.id);
        const owner = poolData?.owner?.toString();
        setDisplayOwner(owner);
        setIsOwner(owner === window?.solana?.publicKey?.toString());
      } catch (error) {
        console.error("Error while getting owner:", error);
      }
    };

    getOwner();
  }, [
    poolDetail,
    loginUser,
    networkAvailable,
    acceptedNetwork,
    window?.solana?.publicKey
  ]);

  if (!isDeployed) return <></>;

  const handleTransferOwnership = async () => {
    // eslint-disable-next-line no-restricted-globals
    if (!confirm("Do you want transfer ownership?")) {
      return false;
    }
    if (!window?.solana?.publicKey) {
      dispatch(alertFailure("Please Connect Solana Wallet"));
      return;
    }
    setLoading(true);
    setDisableButton(true);

    try {
      const baseRequest = new BaseRequest();
      let url = apiRoute(`/solana/transfer-ownership`);
      const body = {
        campaign_id: poolDetail?.id,
        new_owner: address.wallet,
        fee_payer: window?.solana?.publicKey.toString(),
      };
      const response = (await baseRequest.post(url, body)) as any;
      const data = await response.json();
      const tx = await convertToTransaction(data?.data);
      const sig = await SendSolanaTransaction(tx);
      console.log("Token transfer successful.", sig);
      setTransactionHash(sig);
      setIsOpenEditPopup(false);

      setLoading(false);
      dispatch(alertSuccess("Transfer Success !!!"));
      window.location.reload();
    } catch (err: any) {
      setLoading(false);
      setDisableButton(false);
      dispatch(alertFailure("Transfer Fail !!!"));
      setIsOpenEditPopup(false);
      return false;
    }
  };

  const handleWalletChange = (event: any) => {
    if (address === "") {
      setError("");
    } else if (whiteListWallets.includes(address)) {
      setError("");
    } else {
      setError("");
    }
    setAddress(event.target.value);
  };

  const confirmTransfer = () => {
    let error = "";
    if (address === "") {
      error = "Please select Wallet address";
    } else if (address.wallet === displayOwner) {
      error = "Selected Wallet address is already pool owner.";
    } else {
      error = "";
    }
    console.log(error);
    if (!error) {
      setIsOpenEditPopup(true);
    } else {
      setError(error);
    }
  };

  return (
    <div>
      <div className={classes.formControl}>
        <TransferOwnershipForm
          isOpenEditPopup={isOpenEditPopup}
          onConfirmTransfer={handleTransferOwnership}
          address={address}
          setIsOpenEditPopup={setIsOpenEditPopup}
          poolDetail={poolDetail}
          loading={loading}
          disableButton={disableButton}
        />
        <label className={classes.formControlLabel}>Owner</label>
        <p>{displayOwner}</p>
        {acceptedNetwork && (
          <>
            <p style={{ color: "red" }}>
              Please change to correct network Current Network:{" "}
              {CHAIN_ID_NAME_MAPPING[currentNetworkId]} ({currentNetworkId})
            </p>
          </>
        )}
        <label className={classes.formControlLabel}>Transfer Ownership</label>
        <div className={classes.formControlInputLoading}>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel id="network-label">Select Wallet</InputLabel>
            <Select
              labelId="network-label"
              id="network"
              value={address}
              onChange={handleWalletChange}
              label="Wallet"
              error={!!error}
            >
              <MenuItem value=" " disabled>
                Select Wallet
              </MenuItem>
              {whiteListWallets
                .filter(
                  (wallet: any) =>
                    wallet.network === poolDetail.network_available
                )
                .map((value: any, index: any) => {
                  return (
                    <MenuItem key={index} value={value}>
                      {value.wallet}
                    </MenuItem>
                  );
                })}
            </Select>
            {error && <div style={{ color: "red" }}>{error}</div>}
          </FormControl>
        </div>
        {message && <div style={{ color: "red" }}>{message}</div>}
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: "10px" }}
          onClick={confirmTransfer}
          disabled={!isOwner || disableButton || acceptedNetwork}
        >
          Transfer Ownership
        </Button>
        {acceptedNetwork && (
          <>
            <p style={{ color: "red" }}>
              Please change to correct network Current Network:{" "}
              {CHAIN_ID_NAME_MAPPING[currentNetworkId]} ({currentNetworkId})
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default SolanaTransferOwnership;
