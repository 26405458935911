import React from 'react'
import ConfirmDialog from '../../components/Base/ConfirmDialog';

const RecalculateUserTiersForm = (props: any) => {
  const {
    isOpenEditPopup,
    setIsOpenEditPopup,
    onConfirmCalcualte,
    disableButton,
    loading
  } = props;

  const handleSubmitPopup = () => {
    onConfirmCalcualte();
  };
  return (
    <ConfirmDialog
      title={"Recalculate User Tiesr"}
      open={isOpenEditPopup}
      confirmLoading={loading}
      onConfirm={handleSubmitPopup}
      onCancel={() => {
        setIsOpenEditPopup(false);
      }}
      btnLoading = {loading}
    >
      <div>Are you sure you want to recalculate users tiers?</div>
    </ConfirmDialog>
  );
}

export default RecalculateUserTiersForm