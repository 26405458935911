import React from "react";
import useStyles from "./style";
import { DisplayObject } from "./DisplayDetail";
import ConfirmDialog from "./ConfirmDialog";

function LogsDetailModal(props: any) {
  const classes = useStyles();
  const { isOpenEditPopup, setIsOpenEditPopup, logDetail } = props;

  const isJSON = (str : any) => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  };

  const updateDetails = isJSON(logDetail.update_details) 
  ? JSON.parse(logDetail.update_details)
  : logDetail.update_details;

  return (
    <>
      <ConfirmDialog
        title={"Log Details"}
        open={isOpenEditPopup}
        confirmLoading={false}
        onConfirm={() => {
          setIsOpenEditPopup(false);
        }}
        onCancel={() => {
          setIsOpenEditPopup(false);
        }}
      >
        <div className={classes.formControl}>
          <DisplayObject obj={updateDetails} />
        </div>
      </ConfirmDialog>
    </>
  );
}

export default LogsDetailModal;
