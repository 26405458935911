import { Button, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import useStyles from "../../components/Base/ConfirmDialog/style";
import { getMaxCapValues, updatMaxCapValue } from "../../request/config";
import { useDispatch } from "react-redux";
import { alertSuccess } from "../../store/actions/alert";

const MaxCapForm = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [value, setValue] = useState<any>(null);
  const [error, setError] = useState("");
  const [id, setId] = useState(1);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    const response = await getMaxCapValues();
    const data = response.data[0];
    setValue(data.value);
    setId(data.id);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    let error = "";
    event.preventDefault();
    if(value < 0) {
        error = `Max Cap value must be greater than zero`;
        setError(error);
        return
    }
    setLoading(true);
    let response;
    try {
      if (id) {
        response = await updatMaxCapValue(
          {
            value,
          },
          id
        );
      }
      if (response?.status === 200) {
        dispatch(alertSuccess("Max Cap Updated Successfully"))
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setValue(event.target.value);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DefaultLayout>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <div>
          <TextField
            label="Max Cap Value"
            type="number"
            id="maxcap"
            variant="outlined"
            margin="normal"
            fullWidth
            value={value}
            defaultValue={value}
            onChange={handleValueChange}
            error={!!error}
            focused
          />
        </div>
        {error && <div style={{ color: "red" }}>{error}</div>}
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? <div className={styles.loader}></div> : "Submit"}
        </Button>
      </form>
    </DefaultLayout>
  );
};

export default MaxCapForm;
