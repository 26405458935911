import React from "react";
import { Button, TextField } from "@material-ui/core";
import useStyles from "./style";

export default function MultiSigWalletAddress({
  setIsEditMultiSigWallet,
  multiSigWallet,
  handleMultiSigWalletChange,
  errors,
  isEditMultiSigWallet,
  disableButton
}: any) {
  const classes = useStyles();
  return (
    <div>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={classes.rightAlign}
        onClick={() => setIsEditMultiSigWallet(true)}
        disabled = {disableButton}
      >
        Change Address
      </Button>
      <TextField
        label="Multi Sig Wallet Address"
        type="text"
        id="multisigwalletaddress"
        value={multiSigWallet}
        onChange={handleMultiSigWalletChange}
        variant="outlined"
        margin="normal"
        fullWidth
        error={!!errors.multiSigWallet}
        disabled={!isEditMultiSigWallet || disableButton}
      />
      {errors.multiSigWallet && (
        <div className={classes.error}>{errors.multiSigWallet}</div>
      )}
    </div>
  );
}
