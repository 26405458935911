import React from "react";
import useStyles from "../style";
import { renderErrorCreatePool } from "../../../utils/validate";
import ImageUploader from "./ImageUploader";

function PoolBanner(props: any) {
  const classes = useStyles();
  const { register, errors, poolDetail, setValue, isEditDisabled } = props;
  const renderError = renderErrorCreatePool;

  const handleFileSelect = (imageUrl: any) => {
    setValue("banner", imageUrl); // Assuming you're using something like useForm from react-hook-form
  };

  return (
    <>
      <div className={classes.formControl}>
        <label className={classes.formControlLabel}>Pool Banner</label>
        <input
          type="text"
          name="banner"
          defaultValue={poolDetail?.banner}
          ref={register({ required: true })}
          className={classes.formControlInput}
          disabled={isEditDisabled}
          style = {{
            backgroundColor: isEditDisabled ? "#F0F0F0" : "transparent",
          }}
        />

        <p className={classes.formErrorMessage}>
          {renderError(errors, "banner")}
        </p>
        <ImageUploader
          onFileSelect={handleFileSelect}
          width="50%"
          poolId={poolDetail?.id}
          value={poolDetail?.banner}
          disabled={isEditDisabled}
        />
      </div>
    </>
  );
}

export default PoolBanner;
