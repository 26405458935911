import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DefaultLayout from "../../../components/Layout/DefaultLayout";
import { PublicKey } from "@solana/web3.js";
import LamportData from "../Components/LamportData";
import TokenData from "../Components/TokenData";
import TransferOwnership from "../Components/TransferOwnership";
import NewSigner from "../Components/NewSigner";
import CloseTime from "../Components/CloseTime";
import OpenTime from "../Components/OpenTime";
import ChangeSaleToken from "../Components/ChangeSaleToken";
import RefundRemainingTokens from "../Components/RefundRemainingTokens";
import RefundRemainingTokenCurrency from "../Components/RefundRemaininTokenCurrency";
import RefundNativeCurrency from "../Components/RefundNativeCurrency";
import WalletConnect from "../Components/WalletConnect";
import MultiSigWalletAddress from "../Components/MultiSigWalletAddress";
import VaultAddress from "../Components/VaultAddress";
import useStyles from "./style";
import { getPoolDetail } from "../../../request/pool";
import { useDispatch } from "react-redux";
import { alertFailure } from "../../../store/actions/alert";
import moment from "moment";
import { DATETIME_FORMAT } from "../../../constants";
import * as anchor from "@project-serum/anchor";
import SetOfferedLamportsDecimals from "../Components/SetOfferedLamportsDecimals";
import SetOfferedLamportsRate from "../Components/SetOfferedLamportsRate";
import SetOfferedTokenRate from "../Components/SetOfferedTokenRate";
import SetOfferedTokenDecimals from "../Components/SetOfferedTokenDecimals";
import { UseCheckPermissions } from "../../../utils/useCheckPermissions";
import { getPoolMetadata } from "../../../utils";

const MultiSigDetail = () => {
  const classes = useStyles();
  let { id }: any = useParams();
  const [poolDetail, setPoolDetail] = useState<any>({});
  const dispatch = useDispatch();

  useEffect(() => {
    getPoolDetail(id)
      .then(async (res) => {
        if (res.status !== 200) {
          dispatch(
            alertFailure(
              "Server Error: " + (res.message || "Load pool fail !!!")
            )
          );
          return false;
        }
        const data = res.data;
        const newData = {
          ...data,
          start_time: data?.start_time
            ? moment.unix(data?.start_time).format(DATETIME_FORMAT)
            : null,
          finish_time: data?.finish_time
            ? moment.unix(data?.finish_time).format(DATETIME_FORMAT)
            : null,
          release_time: data?.release_time
            ? moment.unix(data?.release_time).format(DATETIME_FORMAT)
            : null,
          start_join_pool_time: data?.start_join_pool_time
            ? moment.unix(data?.start_join_pool_time).format(DATETIME_FORMAT)
            : null,
          end_join_pool_time: data?.end_join_pool_time
            ? moment.unix(data?.end_join_pool_time).format(DATETIME_FORMAT)
            : null,
          announcement_time: data?.announcement_time
            ? moment.unix(data?.announcement_time).format(DATETIME_FORMAT)
            : null,
          start_pre_order_time: data?.start_pre_order_time
            ? moment.unix(data?.start_pre_order_time).format(DATETIME_FORMAT)
            : null,
          listing_time: data?.listing_time
            ? moment.unix(data?.listing_time).format(DATETIME_FORMAT)
            : null,
          start_refund_time: data?.start_refund_time
            ? moment.unix(data?.start_refund_time).format(DATETIME_FORMAT)
            : null,
          end_refund_time: data?.end_refund_time
            ? moment.unix(data?.end_refund_time).format(DATETIME_FORMAT)
            : null,
          first_time_claim_phase: data?.first_time_claim_phase
            ? moment.unix(data?.first_time_claim_phase).format(DATETIME_FORMAT)
            : null,
        };

        setPoolDetail(newData);

        return res.data;
      })
      .catch((e) => {
        console.log("Error: ", e);
        dispatch(alertFailure("Pool load fail !!!"));
      });
  }, [id]);

  const [multiSigWallet, setMultiSigWallet] = useState(
    process.env.REACT_APP_MULTI_SIG_WALLET
  );
  const [vaultWallet, setVaultWallet] = useState<PublicKey>(
    new PublicKey(process.env.REACT_APP_VAULT_ADDRESS || "")
  );
  const [errors, setErrors] = useState<any>("");
  const [isEditMultiSigWallet, setIsEditMultiSigWallet] = useState(false);
  const [isEditVaultWallet, setIsEditVaultWallet] = useState(false);

  const handleMultiSigWalletChange = (e: any) => {
    if (multiSigWallet) {
      setErrors("");
    }
    setMultiSigWallet(e.target.value);
  };

  const handleVaultAddressChange = (e: any) => {
    if (vaultWallet) {
      setErrors("");
    }
    setVaultWallet(e.target.value);
  };

  const pool_index = poolDetail?.pool_index;

  const [currentOwner, setCurrentOwner] = useState("");
  const [currentSigner, setCurrentSigner] = useState("");
  const [currentOpenTime, setCurrentOpenTime] = useState<anchor.BN>(new anchor.BN(0));
  const [currentCloseTime, setCurrentCloseTime] = useState<anchor.BN>(new anchor.BN(0));
  const [isValidVault, setIsValidVault] = useState(false);

  const getOwner = useCallback(async () => {
    if (!poolDetail) return;

    if (!window?.solana?.publicKey) return;

    try {
      const poolData = await getPoolMetadata(id);
      const owner = poolData?.owner?.toString();
      const signer = poolData?.signer.toString();
      setCurrentSigner(signer);
      setCurrentOwner(owner);
      setCurrentOpenTime(poolData.openTime);
      setCurrentCloseTime(poolData.closeTime);
      setIsValidVault(owner === vaultWallet.toString());
    } catch (error) {
    }
  }, [poolDetail, window?.solana?.publicKey, vaultWallet]);

  useEffect(() => {
    getOwner()
  }, [pool_index, id, poolDetail, window?.solana?.publicKey, getOwner])

  const isEditSolanaMultiSig = UseCheckPermissions("EDIT_SOLANA_MULTISIG");

  return (
    <DefaultLayout>
      <WalletConnect />
      <div>
        <MultiSigWalletAddress
          setIsEditMultiSigWallet={setIsEditMultiSigWallet}
          multiSigWallet={multiSigWallet}
          handleMultiSigWalletChange={handleMultiSigWalletChange}
          errors={errors}
          isEditMultiSigWallet={isEditMultiSigWallet}
          disableButton = {!isEditSolanaMultiSig}
        />
        <VaultAddress
          setIsEditVaultWallet={setIsEditVaultWallet}
          vaultWallet={vaultWallet}
          errors={errors}
          handleVaultAddressChange={handleVaultAddressChange}
          isEditVaultWallet={isEditVaultWallet}
          disableButton = {!isEditSolanaMultiSig}
        />
      </div>

      <div className={classes.function}>
        <h3>Owner Functions</h3>

        <LamportData
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <TokenData
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <TransferOwnership
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          currentOwner = {currentOwner}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <NewSigner
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          currentSigner = {currentSigner}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <CloseTime
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          currentCloseTime = {currentCloseTime}
          currentOpenTime = {currentOpenTime}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <OpenTime
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          currentOpenTime = {currentOpenTime}
          currentCloseTime = {currentCloseTime}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <ChangeSaleToken
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <RefundRemainingTokens
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
          poolDetail = {poolDetail}
        />
        <RefundRemainingTokenCurrency
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <RefundNativeCurrency
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <SetOfferedTokenDecimals
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <SetOfferedTokenRate
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <SetOfferedLamportsRate
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
        <SetOfferedLamportsDecimals
          vaultAddress={vaultWallet}
          pool_index={pool_index}
          poolId = {id}
          multiSigWallet={multiSigWallet}
          isValidVault = {isValidVault}
          owner = {currentOwner}
          disableButton = {!isEditSolanaMultiSig}
        />
      </div>
    </DefaultLayout>
  );
};

export default MultiSigDetail;
