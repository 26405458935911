import React from 'react'
import { Link } from 'react-router-dom'
import useStyles from './style'

type IButton = {
  text: string;
  icon?: any;
  to: string | any;
  spacing?: number;
  className?: string;
  onClick?: any;
  children?: JSX.Element | React.ReactNode;
  disabled?:boolean
}

const ButtonLink: React.FC<IButton> = (props: IButton) => {
  const { text, icon, spacing = 10, to, className = '', onClick, disabled } = props;
  const classes = useStyles({
    spacing
  });

  const cssClass = className || classes.button;


  return (
    <Link
      className={cssClass}
      {...( onClick && { onClick })}
      to={to}
      style={{
        pointerEvents: disabled ? "none": "inherit",
        backgroundColor: disabled ? "#F0F0F0" : undefined,
        color: disabled ? "#000" : undefined,
      }}
    >
      <p className={classes.buttonContent}>
          {
            icon && <img src={`/images/${icon}`} alt=""/>
          }
          <span className={classes.buttonText} style={{
            color: disabled ? "#000" : undefined
          }}>{text}</span>
      </p>
    </Link>
  )
}

export default ButtonLink;
