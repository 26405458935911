import React, { useEffect } from "react";
import ConfirmDialog from "../../../components/Base/ConfirmDialog";
import useStyles from "../../PoolCreate/style";
import { generateLink } from "../../../request/pool";
import CopyToClipboard from "react-copy-to-clipboard";
import LinkIcon from "@material-ui/icons/Link";
import Transition from "../../../components/Base/Transition";
import dialogStyles from "../../../components/Base/ConfirmDialog/style";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Button, CircularProgress } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { alertSuccess } from "../../../store/actions/alert";

const GenerateLinkForm = (props: any) => {
  const classes = useStyles();
  const styles = dialogStyles();
  const {
    isOpenEditPopup,
    setIsOpenEditPopup,
    disableButton,
    generatedLink,
    handleCopyToClipboard,
    copied,
    loadingLink,
  } = props;
  const dispatch = useDispatch();

  const handleSubmitPopup = () => {
    setIsOpenEditPopup(false);
  };

  useEffect(() => {
    if (copied) {
      dispatch(alertSuccess("Link copied to clipboard")); // Dispatch the action when copied is true
    }
  }, [copied, dispatch]);

  console.log(generatedLink, "generatedLinks");
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* <ConfirmDialog
        title={""}
        open={isOpenEditPopup}
        confirmLoading={disableButton}
        onConfirm={handleSubmitPopup}
        onCancel={() => {
          setIsOpenEditPopup(false);
        }}
      > */}
      <Dialog
        open={isOpenEditPopup}
        TransitionComponent={Transition}
        aria-labelledby="form-dialog-title"
        className={styles.dialog}
      >
        <DialogTitle id="form-dialog-title">
          Shareable Link
          <img
            src="/images/icon_close.svg"
            alt=""
            className="icon-close"
            onClick={() => handleSubmitPopup()}
            style={{ float: "right" }}
          />
        </DialogTitle>
        <DialogContent className={styles.dialogContent}>
          {loadingLink ? (
            <div
              style={{
                width: "25",
                height: "25",
                position: "absolute",
                top: "50%",
                left: "280px",
                transform: "translateY(-50%)",
              }}
            >
              <CircularProgress size={25} />
            </div>
          ) : (
            <div style={{ marginBottom: "78px" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className={classes.formControl} style={{ width: "100%" }}>
                  <input
                    type="text"
                    name="generate"
                    maxLength={255}
                    className={classes.formControlInput}
                    value={generatedLink}
                    readOnly
                  />
                  <div style={{ display: "flex" }}>
                    <CopyToClipboard
                      text={generatedLink}
                      onCopy={handleCopyToClipboard}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          marginTop: "10px",
                          marginLeft: "472px",
                          borderRadius: "20px",
                        }}
                      >
                        Copy
                      </Button>
                    </CopyToClipboard>
                  </div>
                  {/* <div>
                  {copied && dispatch(alertSuccess("Create success"))}
                </div> */}
                </div>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>

      {/* </ConfirmDialog> */}
    </div>
  );
};

export default GenerateLinkForm;
